import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { baseUrl } from '../../../services/config'
import { toast } from 'react-hot-toast'

const PublishModal = ({
    modalVisible,
    item,
    closeModal,
    reload,
    foldable
}) => {



    const [isLoading, setisLoading] = useState(false)

    const authToken = localStorage.getItem("userToken");


    const onPublish = () => {
        let url1=`admin/versions/${item.id}/publish`;
        let url2=`admin/fold-able-versions/${item.id}/publish`;
        setisLoading(true)
        fetch(`${baseUrl}/${foldable  ? url2 : url1}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,

            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setisLoading(false)
                    closeModal()
                    reload()

                } else if (!data.metadata.success) {
                    Object.values(data.metadata.message).map(msg => {
                        setisLoading(false)
                        toast.error(msg)

                    })
                }
            }).catch(err => {
                console.log(err);
                setisLoading(false)

            })
    }


    return (
        <Modal
            onHide={closeModal}
            animation

            show={modalVisible}>
            <Modal.Header>
                <Modal.Title>Publish Version</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>Are you sure you want to publish this version?</span>
            </Modal.Body>
            <Modal.Footer>
                {
                    isLoading ?
                        <div>
                            <Spinner animation='border' />
                        </div>
                        :
                        <>
                            <button onClick={onPublish} className='btn btn-primary'>Publish</button>
                            <button onClick={closeModal} className='btn btn-danger'>Cancel</button>

                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default PublishModal