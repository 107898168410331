import React from 'react'

function UsePagination({ links, onPaginate }) {
    return (
        <nav>
            <ul class="pagination" style={{ flexWrap: "wrap" }} >
                {
                    links.map((link) => (
                        <li
                            class={`page-item ${link.url == null || link.active ? 'disabled' : 'active'} m-2`} >
                            <a onClick={() => onPaginate(link)} class="page-link" tabindex="1">
                                {link.label == 'Next &raquo;' ? <span>&raquo;</span> : link.label == "&laquo; Previous" ? <span>&laquo;</span> : link.label}
                            </a>
                        </li>
                    ))
                }

            </ul>
        </nav>
    )
}

export default UsePagination