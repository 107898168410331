import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Card, CardBody, CardHeader, CardHeaderToolbar, Pagination } from '../../../_metronic/_partials/controls'
import { baseUrl } from "../../../services/config";
import { useState } from "react";
import * as uiHelpers from "../../modules/ECommerce/pages/products/ProductsUIHelpers";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import usePagination from "../../components/usePagination/usePagination";
import moment from 'moment'
import UsePagination from "../../components/UsePagination";
import { Table } from "react-bootstrap";
import ExportHistoryModal from "./ExportHistoryModal";
const { ExportCSVButton } = CSVExport;


const BoostHistory = () => {

const [openExport,setOpenExport]=useState(false)
    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };

    const [queryParams, setQueryParamsBase] = useState(initialFilter);

    const [links, setlinks] = useState([]);
    // const productsUIContext = useProductsUIContext();
    // const productsUIProps = useMemo(() => {
    //     return {
    //         // ids: productsUIContext.ids,
    //         // setIds: productsUIContext.setIds,

    //         openEditProductPage: productsUIContext.openEditProductPage,
    //         openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
    //     };
    // }, [productsUIContext]);


    const updatingQueryParams = (page) => {
        console.log(page);
        setQueryParamsBase({
            pageNumber: page,
        });
    };

    const onPaginationSelectChange = () => {
        setQueryParamsBase({
            pageNumber: 1,
        });
    };

    const authToken = localStorage.getItem('userToken')

    const [loading, setLoading] = useState(false)
    const [rewardsList, setRewardList] = useState([])


    const getHistory = (url) => {
        setLoading(true)
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setLoading(false)
                    setRewardList(data.payload.data)
                    setlinks(data.payload.links)
                } else {
                    setLoading(false)
                    setRewardList([])
                }
            }).catch(err => {
                setLoading(false)

                console.log(err);
            })
    }



    useEffect(() => {
        getHistory(`${baseUrl}/admin/boosted-wallpaper-history`)
    }, [])



    // Table columns
    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.first_name",
            text: "First Name",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.last_name",
            text: "Last Name",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.email",
            text: "Email",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.gems",
            text: "User Gems",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "requested_wallpaper.name",
            text: "Wallpaper Name",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "requested_wallpaper.rating",
            text: "Rating",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "gems",
            text: "Used Gems",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "timestamp",
            text: "Boosted At",
            sort: true,
            formatter: (cell)=>(
                <span>
                    {console.log(cell)}
                    {moment(new Date(`${cell}`)).format("DD/MM/YYYY HH:mm:ss")}
                </span>
            ),
        },


    ];

    const paginationOptions = {
        custom: true,
        totalSize: rewardsList?.length,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
    };


    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-success" onClick={handleClick}>Export CSV</button>
            </div>
        );
    };
    

    const onPaginate = (link) => {
        getHistory(link?.url);
      };

    return (
        <Card >
                  <CardHeader title="Boost History">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=>setOpenExport(true)}
            >
              Export
            </button>
          </CardHeaderToolbar>
        </CardHeader>
            <CardBody>

            {
              rewardsList.length>0 ?
              <>
              <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>FIRST NAME</th>
                            <th>LAST NAME</th>
                            <th>EMAIL</th>
                            <th>USER GEMS</th>
                            <th>WALLPAPER NAME</th>
                            <th>RATING</th>
                            <th>USED GEMS</th>
                            <th>BOOSTED AT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rewardsList?.map((item)=>(
                                <tr>
                                <td>{item.id}</td>
                                <td>{item.user?.first_name}</td>
                                <td>{item.user?.last_name}</td>
                                <td>{item.user?.email}</td>
                                <td>{item.user?.gems}</td>
                                <td>{item.requested_wallpaper?.name}</td>
                                <td>{item.requested_wallpaper?.rating}</td>
                                <td>{item.gems}</td>
                                <td> {item.timestamp}</td>
                              </tr>
                            ))
                          
                        }
                        <tr>

                        </tr>
                    </tbody>
                </Table>
                <UsePagination links={links} onPaginate={onPaginate} />
              </>
              :''
            }
                {/* <ToolkitProvider
                    keyField="id"
                    data={rewardsList}
                    columns={columns}
                    exportCSV
                >
                    {
                        props => (

                            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                                {({ paginationProps, paginationTableProps }) => {
                                    return (
                                        <>
                                            <Pagination
                                                updatingQueryParams={updatingQueryParams}
                                                onPaginationSelectChange={onPaginationSelectChange}
                                                isLoading={loading}
                                                paginationProps={paginationProps}
                                            >
                                                <MyExportCSV {...props.csvProps} />
                                                <BootstrapTable
                                                    wrapperClasses="table-responsive"
                                                    bordered={false}
                                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                                    bootstrap4
                                                    keyField="id"
                                                    data={rewardsList}
                                                    columns={columns}
                                                    defaultSorted={uiHelpers.defaultSorted}
                                                    {...paginationTableProps}
                                                    {...props.baseProps}
               
                                                ></BootstrapTable>
                                            </Pagination>
                                      
                                        </>
                                    );
                                }}
                            </PaginationProvider>
                        )
                    }
                </ToolkitProvider> */}
<ExportHistoryModal visible={openExport} HideModal={()=>setOpenExport(false)}/>
            </CardBody>
        </Card>
    )
}

export default BoostHistory