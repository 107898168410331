import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Spinner } from "react-bootstrap";
import { Avatar } from '@material-ui/core'
import Form from "react-bootstrap/Form";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// import Upload from "../../../../app/Images/upload.png";
import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import { Link } from 'react-router-dom'
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../../services/config";
import ResetPassword from "./ResetPassword/ResetPassword";
import UsePagination from "./UsePagination";
import EditUser from "./EditUser/EditUser";
import CancelModal from "./CancelModal/CancelModal";

const Subscribeduser = ({url}) => {





  useEffect(() => {
    getUsers(url);
  }, [url]);


  const authToken = localStorage.getItem("userToken");
  const [editModal, seteditModal] = useState(false);
  const [cancelModal, setcancelModal] = useState(false);
  const [userObject, setuserObject] = useState({});

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetPasswordModal, setresetPasswordModal] = useState(false);
  const [links, setlinks] = useState([])
  const [numberofpages, setnumberofpages] = useState(0);
  const [total, settotal] = useState(0);
  const [searchText, setsearchText] = useState('');
  const [selectedId, setselectedId] = useState('');

  const getUsers = url => {
    setLoading(true)
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      }
    }).then(response => response.json())
      .then((data => {

        // let filterdUser = data.payload.data.filter((item) => item.app_purchased == true)
        setUsers(data.payload.data)
        setnumberofpages(data.payload.per_page);
        settotal(data.payload.total);
        setlinks(data.payload.links)

        setLoading(false);
      })).catch(err => {
        console.log(err)
        setLoading(false)

      })
  }


  const onPaginate = link => {
    getUsers(link?.url)

  }


  const openResetModal = (id) => {
    setselectedId(id)
    setresetPasswordModal(true)
  }




  const dataSearch = users.filter((user) => {
    let text = searchText.toLowerCase();
    return searchText ? user?.first_name?.toLowerCase().includes(text)
      ||
      user?.last_name?.toLowerCase().includes(text)
      ||
      user?.email?.toLowerCase().includes(text)

      :
      true
  })



  const openCancelModal = id => {
    setselectedId(id)
    setcancelModal(true)
  }





  return (
    <div>
      <Paper style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          
          <h3>Subscribed Users</h3>
        </div>
        <hr style={{ marginLeft: "-20px", marginRight: "-20px" }} />

        <form className="form-label-right">
          <div className="form-group row">
            {/* <Form.Group className="mb-3 col-md-3" controlId="formBasicEmail">
              <Form.Label>THLN</Form.Label>
              <Form.Control type="text" placeholder="THLN" />
            </Form.Group> */}

            <div className="col-md-6">
              <Form.Label>Search</Form.Label>
              <input
                onChange={(e) => setsearchText(e.target.value)}
                type="text"
                className="form-control"
                name="searchText"
                placeholder="Search"
              />

              {/* <small className="form-text text-muted">
                <b>Search</b> in all fields
              </small> */}
            </div>
            {/* 
            <div className="col-lg-6">
              <Form.Label>Filter</Form.Label>
              <select
                className="form-control"
                name="status"
                onChange={(e) => setsearchText(e.target.value)}
                placeholder="Select Law"
              >
                <option value>Select Filter</option>
                <option value="All Users">All Users</option>
                <option value="Subscribed Users">Subscribed Users</option>
              </select>
            </div> */}
          </div>
        </form>

        <Table responsive>
          <thead>
            <tr>
              {/* <th>#</th> */}
              <th style={{ verticalAlign: "center" }}>Image</th>
              <th style={{ verticalAlign: "center" }}>Name</th>
              <th style={{ verticalAlign: "center" }}>Email</th>
              <th style={{ verticalAlign: "center" }}>Phone</th>
              <th style={{ verticalAlign: "center" }}>Gems</th>
              <th style={{ verticalAlign: "center" }}>Order No</th>
              <th style={{ verticalAlign: "center" }}>Edit</th>
              <th style={{ verticalAlign: "center" }}>Reset Password</th>
              <th style={{ verticalAlign: "center" }}>Cancel Subscription</th>
            </tr>
          </thead>
          <tbody>
            {
              dataSearch && dataSearch.map((user, index) => (
                <tr key={user.id}>
                  <td style={{ verticalAlign: "center" }}>
                    <Avatar alt={user.first_name} src={user?.avatar?.original_url} />

                  </td>
                  <td style={{ verticalAlign: "center" }}>{user.first_name + user.last_name}</td>
                  <td style={{ verticalAlign: "center" }}>{user.email}</td>
                  <td style={{ verticalAlign: "center" }}>{user.phone}</td>
                  <td style={{ verticalAlign: "center" }}>

                    {user.gems}
                  </td>
                  <td style={{ verticalAlign: "center" }}>

                    {user?.subscription?.google_id}
                  </td>

                  <td>
                    <a
                      onClick={() => {
                        setuserObject(user);
                        seteditModal(true);
                      }}
                      style={{ verticalAlign: "center" }}
                      className="btn btn-icon btn-light btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                  <td onClick={() => openResetModal(user.id)}>
                    <a style={{ verticalAlign: "center" }} className="btn btn-icon btn-light btn-sm">
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Home/Key.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                  <td
                    onClick={() => openCancelModal(user.id)}
                  >
                    <a style={{ verticalAlign: "center" }} className="btn btn-icon btn-light btn-sm">
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Close.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                </tr>

              ))
            }


          </tbody>
        </Table>
        {
          loading &&
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner animation="border" />
          </div>
        }


        <UsePagination
          links={links}
          onPaginate={onPaginate}

        />

        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          {
            links.map((link, index) => (
              <button
                className={`btn m-2 ${link.active ? 'btn-primary' : 'btn-info'}`}
                disabled={link.url == null || link.active ? true : false}
                onClick={() => onPaginate(link)}>
                <div>{link.label == 'Next &raquo;' ? 'Next' : link.label == "&laquo; Previous" ? 'Previous' : link.label}</div>
              </button>
            ))
          }
        </div> */}
      </Paper>


      <ResetPassword
        visible={resetPasswordModal}
        userId={selectedId}
        hideModal={() => setresetPasswordModal(false)}
      />

      <EditUser
        reload={() => getUsers(url)}
        visible={editModal}
        userObject={userObject}
        hideModal={() => seteditModal(false)}
      />

      <CancelModal
        HideModal={() => setcancelModal(false)}
        visible={cancelModal}
        id={selectedId}
        reload={() => getUsers(url)}
      />
    </div>
  );
};

export default Subscribeduser;
