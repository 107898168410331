import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import "./Side.css";
import Imagecard from "../components/Imagecard";
import { baseUrl } from "../../services/config";
import Updatecomponent from "../components/Updatecomponent";
import { toast } from "react-hot-toast";
import AddWallpaperModal from "../components/AddWallpaperModal/AddWallpaperModal";

const Request = ({ foldable }) => {
  const authToken = localStorage.getItem("userToken");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (wallapersList.length >= 1) {
      toast.error("You Can Just Add 1 Side Banners");
    }
    else {
      setShow(true);
    }

  };

  const [wallapersList, setwallapersList] = useState([]);

  const handleChangeImages = (e) => {
    let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";
    // for (let i = 0; i < e.target.files.length; i++) {
    //     imagesArray.push(e.target.files[i]);
    // }
    imagesArray.push(img);
    setimages(imagesArray);
    console.log(imagesArray);
  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    fileObj.push(event.target.files[0]);
    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }
    // console.log("file array",fileArray)
    // console.log("file obj",fileObj)
  };

  const [startDate, setStartDate] = useState(new Date());

  const [showlist, setshowlist] = useState([]);
  const [images, setimages] = useState([]);
  const [loading, setloading] = useState(false);

  const [updateModal, setupdateModal] = useState(false);
  const [selectedObject, setselectedObject] = useState({});

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });
    setimages(newArr2);
    setshowlist(newArr);
  };

  useEffect(() => {
    getSideWallapers();
  }, [foldable]);

  const getSideWallapers = () => {

    let url1 = "admin/banners/index/requested";
    let url2 = "admin/fold-able-banners/index/requested";
    setloading(true)
    fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false)
        setwallapersList(data.payload);
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };

  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [img, setimg] = useState("");

  //start save api
  const Requestsave = () => {
    const fdata = new FormData();
    fdata.append("type", "requested");
    fdata.append("url", url);
    fdata.append("image", img);

    // Axios.post(`${baseUrl}/admin/banners`, fdata, {
    //   Authorization: `Bearer 13|1zboAQruugqNf2Mbny3IqQW6RGnTxwJ9jXrngKQG`,
    // })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setloading(true)
    fetch(`${baseUrl}/admin/banners`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          setloading(false)
          setShow(false);
          getSideWallapers();
        }
        else {
          setloading(false)
        }
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };
  //end save api

  const editWallpaper = (card) => {
    setselectedObject(card);
    setupdateModal(true);
  };

  return (
    <>
      {loading == true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="spinner-border text-dark  p-5 m-5 " role="status">
            <span class="sr-only ">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              // title={wallapersList.length >= 1 ? "You can just 3 banners" : ""}
              // disabled={wallapersList.length >= 1 ? true : false}
              className="mr-2 mb-2"
              variant="primary"
              onClick={handleShow}
            >
              Add New
            </Button>
          </div>
          <div className="row">
            {wallapersList &&
              wallapersList.map((item) => (
                <Imagecard
                  foldable={foldable}

                  key={item.id}
                  onEditClick={(card) => editWallpaper(card)}
                  reload={() => getSideWallapers()}
                  card={item}
                  title="Request"
                />
              ))}
          </div>
        </>
      )}

      {/* add new modal start */}
      {/* <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Requested</Modal.Title>
        </Modal.Header>
        {loading == true ? (
          
          <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="spinner-border text-dark  p-5 m-5 " role="status">
            <span class="sr-only ">Loading...</span>
          </div>
        </div>
        ) : (

        <Modal.Body className="row">
          <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="URL"
              onChange={(e) => {
                seturl(e.target.value);
              }}
            />
          </Form.Group>

          <div className="col-12">
            <div
              className="avatar-upload-input text-center "
              style={{ marginTop: "20px" }}
            >
              <img src={Upload} alt="img" />
              <h2 className="mt-3">Upload File</h2>

              <div className="avatar-edit-input">
                <input
                  onChange={(e) => {
                    setimg(e.target.files[0]);
                  }}
                  className="btn btn-base"
                  style={{ textAlignLast: "center" }}
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {showlist.map((pic, i) => (
                <div className="pic" key={i + 1}>
                  <img
                    key={i + 1}
                    height={150}
                    width={150}
                    style={{ margin: 5 }}
                    src={pic}
                  />
                  <button
                    className="danger"
                    onClick={() => removeImage(i)}
                    style={{ border: "none" }}
                  >
                    <p style={{ color: "red" }}>X</p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={Requestsave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/*add new modal end */}

      <AddWallpaperModal
        foldable={foldable}

        visible={show}
        type="requested"
        handleCloseModal={() => setShow(false)}
        reload={() => getSideWallapers()}

      />

      <Updatecomponent
        foldable={foldable}

        url={selectedObject.url}
        type={"requested"}
        title="Request"
        show={updateModal}
        hide={() => setupdateModal(false)}
        reload={() => getSideWallapers()}
        wallpaperobject={selectedObject}
      />
    </>
  );
};

export default Request;
