import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Formik, useFormik, Form, Field, } from "formik";
import * as Yup from "yup";
import { Input, ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { baseUrl } from "../../../services/config";
import Axios from "axios";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";


function EditUser({
    visible,
    hideModal,
    reload,
    userObject
}) {
    // Fields



    console.log(userObject)



    const authtoken = localStorage.getItem("userToken");

    const [loading, setloading] = useState(false);
    const [pic, setPic] = useState("");

    const [remoteImage, setremoteImage] = useState(userObject?.avatar?.original_url)
    const [viewImage, setviewImage] = useState('');

    const [enable, setenable] = useState(false);
    const [user, setuser] = useState(userObject)
    const dispatch = useDispatch();



    const getUserDetials = async () => {
        await fetch(`${baseUrl}/profiles`, {
            headers: {
                Authorization: `Bearer ${authtoken}`
            }
        }).then(res => res.json())
            .then((data) => {
                console.log(data)
                setuser(data.payload)
            }).catch(err => {
                console.log(err)
            })
    }





    const saveUser = (values) => {
        setloading(true);
        console.log(values)

        let formData = new FormData();


        formData.append('first_name', values.first_name)
        formData.append('last_name', values.last_name)
        formData.append('email', values.email)
        formData.append('phone', values.phone)
        formData.append('gems', values.gems)
        if (pic) {
            formData.append('avatar', pic);
        }


        const data = {
            avatar: values.avatar,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,

        }
        Axios.post(`${baseUrl}/admin/users/${userObject.id}?_method=PUT`, formData, {
            headers: {
                Authorization: `Bearer ${authtoken}`
            }
        }).then(res => {
            console.log(res)
            if (res.data.metadata.success) {
                toast.success(res.data.metadata.message)
                setloading(false)
                getUserDetials()
                setPic('')
                setviewImage('')
                hideModal()
                reload()
            }
            else if (!res.data.metadata.success) {
                Object.values(res.data.metadata.message).map(msg => {
                    console.log("msg is =>", msg)
                    toast.error(msg)
                    setloading(false);
                })

            }
        }).catch(err => {
            console.log(err.response)
            setloading(false);
        })


    };


    // UI Helpers
    const initialValues = {

        first_name: userObject.first_name,
        last_name: userObject.last_name,
        phone: userObject.phone,
        email: userObject.email,
        gems: userObject.gems
    };


    const Schema = Yup.object().shape({

        email: Yup.string().required('Please Enter Email'),
        name: Yup.string().required('Please enter Name'),

        phone: Yup.string().required("Phone is required")
        .min(11,'Number must be 11 digits')
        .max(11, 'Number must be 11 digits'),
        designation: Yup.string().required('Please enter desigation'),
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveUser(values, setStatus, setSubmitting);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });
    const getUserPic = () => {
        if (!pic) {
            return "none";
        }
        // URL.createObjectURL(pic[0])
        return URL.createObjectURL(pic[0]);
    };

    const onChangePic = (file) => {
        setviewImage(URL.createObjectURL(file))
        setenable(true)
        setPic(file);
    }


    const removePic = () => {
        setPic("");
        setviewImage('')
    };


    console.log(enable)
    return (
        <Modal
            size="xl"
            show={visible}

        >
            <div className="card card-custom card-stretch">
                <Formik

                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={Schema}
                    // validate={validatefield }
                    onSubmit={(values) => {
                        saveUser(values)
                    }}
                >
                    {({ handleSubmit, handleChange, setValues, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => (
                        <Form>
                            {loading && <ModalProgressBar />}

                            {/* begin::Header */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="card-header py-5">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="card-label font-weight-bolder text-dark">
                                        Personal Information
                                    </h3>
                                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                                        Update your personal informaiton
                                    </span>
                                </div>
                                <div className="card-toolbar">
                                    <button
                                        onClick={() => saveUser(values)}
                                        type="submit"
                                        className="btn btn-success mr-2"
                                        disabled={
                                            formik.isSubmitting}>
                                        Save Changes
                                        {formik.isSubmitting}
                                    </button>
                                    <button
                                        onClick={()=>{
                                            hideModal()
                                            setenable(false)
                                        }}
                                        className="btn btn-danger"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            {/* end::Header */}
                            {/* begin::Form */}

                            <div className="form">

                                {/* begin::Body */}
                                <div className="card-body">
                                    <div className="row">
                                        <label className="col-xl-3"></label>
                                        <div className="col-lg-9 col-xl-6">
                                            <h5 className="font-weight-bold mb-6">Customer Info</h5>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                                        {
                                            enable ?
                                                <div className="col-lg-9 col-xl-6">
                                                    <div
                                                        className="image-input image-input-outline"
                                                        id="kt_profile_avatar"
                                                        style={{
                                                            backgroundImage: `url(${toAbsoluteUrl(
                                                                "/media/users/blank.png"
                                                            )}`,
                                                        }}
                                                    // style={{ backgroundImage: viewImage ? viewImage : `url(${toAbsoluteUrl(userObject?.avatar?.original_url)}` }}
                                                    >
                                                        <div
                                                            className="image-input-wrapper"
                                                            // style={{ backgroundImage: `url(${toAbsoluteUrl(remoteImage)}` }}
                                                            style={{
                                                                backgroundImage: `url(${toAbsoluteUrl(viewImage)}`

                                                            }}
                                                        />
                                                        <label
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="change"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Change avatar"
                                                        >
                                                            <i className="fa fa-pen icon-sm text-muted"></i>
                                                            <input
                                                                type="file"
                                                                name="avatar"
                                                                accept=".png, .jpg, .jpeg"
                                                                onChange={(e) => onChangePic(e.target.files[0])}
                                                            />
                                                            <input type="hidden" name="profile_avatar_remove" />
                                                        </label>
                                                        <span
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="cancel"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Cancel avatar"
                                                        >
                                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                        </span>
                                                        <span
                                                            onClick={removePic}
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="remove"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Remove avatar"
                                                        >
                                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                        </span>
                                                    </div>
                                                    <span className="form-text text-muted">
                                                        Allowed file types: png, jpg, jpeg.
                                                    </span>
                                                </div>
                                                :
                                                enable === false ?

                                                <div className="col-lg-9 col-xl-6">
                                                    <div
                                                        className="image-input image-input-outline"
                                                        id="kt_profile_avatar"
                                                        style={{
                                                            backgroundImage: `url(${toAbsoluteUrl(
                                                                "/media/users/blank.png"
                                                            )}`,
                                                        }}
                                                    // style={{ backgroundImage: viewImage ? viewImage : `url(${toAbsoluteUrl(userObject?.avatar?.original_url)}` }}
                                                    >
                                                        <div
                                                            className="image-input-wrapper"
                                                            // style={{ backgroundImage: `url(${toAbsoluteUrl(remoteImage)}` }}
                                                            style={{
                                                                backgroundImage: `url(${toAbsoluteUrl(userObject?.avatar?.original_url)}`

                                                            }}
                                                        />
                                                        <label
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="change"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Change avatar"
                                                        >
                                                            <i className="fa fa-pen icon-sm text-muted"></i>
                                                            <input
                                                                type="file"
                                                                name="avatar"
                                                                accept=".png, .jpg, .jpeg"
                                                                onChange={(e) => onChangePic(e.target.files[0])}
                                                            />
                                                            <input type="hidden" name="profile_avatar_remove" />
                                                        </label>
                                                        <span
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="cancel"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Cancel avatar"
                                                        >
                                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                        </span>
                                                        <span
                                                            onClick={removePic}
                                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                            data-action="remove"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Remove avatar"
                                                        >
                                                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                        </span>
                                                    </div>
                                                    <span className="form-text text-muted">
                                                        Allowed file types: png, jpg, jpeg.
                                                    </span>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="form-group row">

                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 col-form-label">
                                            First Name
                                        </label>
                                        <div className="col-lg-9 col-xl-6">
                                            <Field
                                                style={{ height: '4rem' }}
                                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                    "first_name"
                                                )}`}
                                                name="first_name"
                                                component={Input}
                                                placeholder="Name"

                                            />
                                            {touched.first_name && errors.first_name ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.first_name}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 col-form-label">
                                            Last Name
                                        </label>
                                        <div className="col-lg-9 col-xl-6">
                                            <Field
                                                style={{ height: '4rem' }}
                                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                    "last_name"
                                                )}`}
                                                name="last_name"
                                                component={Input}
                                                placeholder="last Name"

                                            />
                                            {touched.last_name && errors.last_name ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.last_name}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 col-form-label">
                                            Contact Phone
                                        </label>
                                        <div className="col-lg-9 col-xl-6">
                                            <div className="input-group input-group-lg input-group-solid">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-phone"></i>
                                                    </span>
                                                </div>
                                                <Field
                                                    style={{ height: '4rem' }}
                                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                        "phone"
                                                    )}`}
                                                    name="phone"
                                                    component={Input}
                                                    placeholder="Phone"

                                                />


                                            </div>

                                            <span className="form-text text-muted">
                                                We'll never share your phone with anyone else.
                                            </span>
                                            {touched.phone && errors.phone ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.phone}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 col-form-label">
                                            Gems
                                        </label>
                                        <div className="col-lg-9 col-xl-6">
                                            <Field
                                                style={{ height: '4rem' }}
                                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                    "gems"
                                                )}`}
                                                name="gems"
                                                component={Input}
                                                placeholder="Gems"

                                            />


                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                        Company Name
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <Field
                                            style={{ height: '4rem' }}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                "companyName"
                                            )}`}
                                            name="companyName"
                                            component={Input}
                                            placeholder="Company Name"

                                        />

                                    </div>
                                </div> */}
                                    {/* <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                        City
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <Field
                                            style={{ height: '4rem' }}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                "city"
                                            )}`}
                                            name="city"
                                            component={Input}
                                            placeholder="City"

                                        />

                                    </div>
                                </div> */}
                                    {/* <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                        Address
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                        <Field
                                            style={{ height: '4rem' }}
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                "address"
                                            )}`}
                                            name="address"
                                            component={Input}
                                            placeholder="Address"

                                        />

                                    </div>
                                </div> */}


                                    {/* <div className="row">
                                    <label className="col-xl-3"></label>
                                    <div className="col-lg-9 col-xl-6">
                                        <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
                                    </div>
                                </div> */}

                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 col-form-label">
                                            Email Address
                                        </label>
                                        <div className="col-lg-9 col-xl-6">
                                            <div className="input-group input-group-lg input-group-solid">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-at"></i>
                                                    </span>
                                                </div>
                                                <Field
                                                    style={{ height: '4rem' }}
                                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                        "email"
                                                    )}`}
                                                    name="email"
                                                    component={Input}
                                                    placeholder="Email"

                                                />
                                            </div>
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="invalid-feedback display-block">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Site
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div className="input-group input-group-lg input-group-solid">
                                        <input
                                            type="text"
                                            placeholder="https://keenthemes.com"
                                            className={`form-control form-control-lg form-control-solid`}
                                            name="website"
                                            {...formik.getFieldProps("website")}
                                        />
                                    </div>
                                    {formik.touched.website && formik.errors.website ? (
                                        <div className="invalid-feedback display-block">
                                            {formik.errors.website}
                                        </div>
                                    ) : null}
                                </div>
                            </div> */}
                                </div>
                                {/* end::Body */}
                            </div>
                            {/* end::Form */}

                        </Form>
                    )}


                </Formik>

            </div>
        </Modal>

    );
}

export default EditUser
