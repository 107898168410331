import React, { useState, Component, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import toast from 'react-hot-toast'
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-multi-date-picker";
import { baseUrl } from "../../services/config";
import EditPromo from "../components/EditPromo/EditPromo";

const GiveawayCodes = () => {


    const [addLoading, setaddLoading] = useState(false)
    const [loading, setloading] = useState(false)
    const authToken = localStorage.getItem("userToken");
    const [deleteLoading, setdeleteLoading] = useState(false)
    const [delLoading, setdelLoading] = useState(false)
    const [selectedObject, setselectedObject] = useState({})
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedIds, setselectedIds] = useState([]);
    const [PromocodesList, setPromocodesList] = useState([]);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [editModal, seteditModal] = useState(false);



    //multiple select states
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const handleShow2 = id => {

        let arr = []
        arr.push(id);
        setselectedIds(arr)

        setShow2(true)
    };

    //   const [startDate, setStartDate] = useState(new Date());
    const [value, setValue] = useState(new Date());



    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };



    const initialValues = {
        count: "",
        gems: "",
        multiple: "Giveaway Codes",
        expiry: "",

    };


    const LoginSchema = Yup.object().shape({
        count: Yup.string()
            .required('Count is required'),
        gems: Yup.string()
            .required('Gems is required'),
        multiple: Yup.string()
            .required('Please Select code type'),
        expiry: Yup.string()
            .required('Expiry Date  is required')

    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setaddLoading(true)

            const data = {
                count: values.count,
                gems: values.gems,
                multiple: values.multiple == "Giveaway Codes" ? 1 : values.multiple == "Limited Codes" ? 0 : '',
                expiry: values.expiry
            }
            console.log(data)
            fetch(`${baseUrl}/admin/promo-codes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((data) => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message);
                        setSubmitting(false)
                        getCodes()
                        setaddLoading(false)
                        handleClose()

                    }
                    else if (!data.metadata.success) {
                        Object.values(data.metadata.message).map(msg => {
                            toast.error(msg)
                            setaddLoading(false)
                            setSubmitting(false)
                        })
                    }
                    console.log(data)
                }).catch(err => {
                    setSubmitting(false)
                    setaddLoading(false)

                    console.error("add codes error", err)
                })

        }
    })






    useEffect(() => {
        getCodes()
    }, [])



    const getCodes = () => {
        setloading(true)
        fetch(`${baseUrl}/admin/promo-codes`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {

                const updatedArr = data.payload.filter(item => item.multiple === true)
                let checkboxes = updatedArr;
                updatedArr.map((item, index) => {
                    item["selected"] = false;
                    checkboxes[index] = item;
                });
                setPromocodesList(checkboxes)
                setloading(false)

                console.log(data);
            }).catch(err => {
                setloading(false)
                console.error("codes error", err)
            });

    }




    const deleteCode = () => {
        setdeleteLoading(true)
        fetch(`${baseUrl}/admin/promo-codes/bulk-delete/${selectedIds}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                console.log(data);
                if (data.metadata.success) {
                    handleClose2()
                    getCodes()
                    setdeleteLoading(false)
                    toast.success(data.metadata.message);
                    // setPromocodesList(data.payload);

                }
            })
            .catch(err => {
                console.error("codes error", err)
                setdeleteLoading(false)
            });
    }



    // multiple select

    const onMasterCheck = e => {
        let tempList = PromocodesList;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        let filtered = PromocodesList.filter((e) => e.selected)
        setPromocodesList(tempList);
        setMasterChecked(e.target.checked)
        setSelectedList(filtered)

    }


    const onItemCheck = (e, item) => {
        let tempList = PromocodesList;


        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = PromocodesList.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State
        setMasterChecked(totalItems === totalCheckedItems)
        setPromocodesList(tempList);
        setSelectedList(PromocodesList.filter((e) => e.selected))

    }


    const handleDeleteAll = () => {
        if (SelectedList.length > 0) {

            console.log(SelectedList)
            setdelLoading(true)
            let ids = SelectedList.map(item => item.id)
            console.log(ids)

            fetch(`${baseUrl}/admin/promo-codes/bulk-delete/${ids}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            }).then((response) => response.json())
                .then((data) => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message)
                        setdelLoading(false)
                        getCodes()
                        setSelectedList([])

                    }
                    console.log(data)
                }).catch(err => {
                    setdelLoading(false)
                    console.log(err)
                })


        } else {
            toast.error('Please select some codes')
        }
    }


    let GEMS = [10, 20, 30, 40, 50, 100, 150, 200, 300, 500,1000,1200,1500]



    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                }}
            >
                <Button
                    // title={wallapersList.length >= 2 ? "You Can Add Only 2 Side Banners" : ""}
                    // disabled={wallapersList.length >= 2 ? true : false}
                    className="mr-2 mb-2"
                    style={{ width: "100px" }}
                    variant="primary"
                    onClick={handleShow}
                >
                    Add
                </Button>
            </div>

            <Paper style={{ padding: "20px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    <h3>Promo Code</h3>

                    {
                        delLoading ?
                            <Spinner animation="border" />
                            :
                            <Button

                                className="mr-2 mb-2"

                                variant="danger"
                                onClick={handleDeleteAll}
                            >
                                Delete Selected
                            </Button>

                    }
                </div>
                <hr style={{ marginLeft: "-20px", marginRight: "-20px" }} />

                <form className="form-label-right">
                    <div className="form-group row">
                        {/* <Form.Group className="mb-3 col-md-3" controlId="formBasicEmail">
              <Form.Label>THLN</Form.Label>
              <Form.Control type="text" placeholder="THLN" />
            </Form.Group> */}
                    </div>
                </form>


                <Table responsive>
                    <thead>
                        <tr style={{ position: "relative", left: "1px" }}>
                            <th >#</th>
                            <th >Code</th>
                            <th style={{ marginRight: "2px" }}>Gems</th>
                            <th>Expiry Date</th>
                            <th>Delete</th>
                            <th style={{ position: "relative", right: "17px" }}>
                                <input
                                    type="checkbox"
                                    checked={MasterChecked}
                                    onChange={(e) => onMasterCheck(e)}
                                />
                                {" "}
                                <span>Check All</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            PromocodesList && PromocodesList.map((item, index) => (

                                <tr key={item.id}>

                                    <td style={{ verticalAlign: "initial" }}>{index + 1}</td>
                                    <td style={{ verticalAlign: "initial" }}>{item.code}</td>
                                    <td style={{ verticalAlign: "initial" }}>{item.gems}</td>
                                    <td style={{ verticalAlign: "initial" }}>{item.expiry}</td>


                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span className="svg-icon svg-icon-md svg-icon-danger" onClick={() => handleShow2(item.id)}>
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                                                ></SVG>
                                            </span>
                                        </a>
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={item.selected}
                                            className="form-check-input"
                                            id="rowcheck{user.id}"
                                            onChange={(e) => onItemCheck(e, item)}
                                        />
                                    </td>
                                </tr>


                            ))
                        }
                    </tbody>
                </Table>
                {loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" /></div>}

            </Paper>

            <Modal
                size="lg"
                show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Promo Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <label>Select Count</label>
                                <select
                                    name="count"
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                        "count"
                                    )}`}
                                    placeholder="Select Count"

                                    {...formik.getFieldProps("count")}
                                >
                                    <option value>Select Count</option>
                                    <option value="2">2</option>
                                    <option value="4">4</option>
                                    <option value="6">6</option>
                                    <option value="8">8</option>
                                    <option value="10">10</option>
                                </select>
                                {formik.touched.count && formik.errors.count ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.count}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-lg-4">
                                <label>Select Gems</label>
                                <select
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                        "gems"
                                    )}`}
                                    name="gems"
                                    placeholder="Select Gems"
                                    {...formik.getFieldProps("gems")}
                                >
                                    <option value="">Select Gems</option>
                                    {
                                        GEMS.map(gem => (

                                            <option value={gem}>{gem}</option>
                                        ))
                                    }
                                </select>
                                {formik.touched.gems && formik.errors.gems ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.gems}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-md-4">

                                <label>Select Type</label>
                                <select
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                        "multiple"
                                    )}`}
                                    name="multiple"
                                    placeholder="Select Type"
                                    {...formik.getFieldProps("multiple")}
                                >
                                    <option value="">Select Code type</option>
                                    <option value="Limited Codes">Limited Codes</option>
                                    <option value="Giveaway Codes">Giveaway Codes</option>

                                </select>
                                {formik.touched.multiple && formik.errors.multiple ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.multiple}</div>
                                    </div>
                                ) : null}

                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label>Expiry Date</label>
                                <input name="expiry" type="date" className={`form-control form-control-solid py-5 px-6 h-auto ${getInputClasses(
                                    "multiple"
                                )}`}
                                    {...formik.getFieldProps("expiry")}
                                />
                                {formik.touched.expiry && formik.errors.expiry ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.expiry}</div>
                                    </div>
                                ) : null}

                            </div>


                        </div>

                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={formik.isSubmitting}
                                className={`btn btn-primary`}
                            >
                                <span style={{ textAlign: "center" }}>Generate Code</span>
                                {addLoading && <Spinner animation="border" />}
                            </button>
                        </Modal.Footer>
                    </form>

                </Modal.Body>
            </Modal>




            {/* delete Model */}
            <Modal
                show={show2}
                onHide={handleClose2}
                animation={false}
                size="md"
                style={{ marginLeft: "140px" }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Promo Code</Modal.Title>
                </Modal.Header>

                <Modal.Body className="row">
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <h3 className="text-center">Are you sure ? you want to delete it</h3>

                    </Form.Group>


                </Modal.Body>


                <Modal.Footer>
                    {
                        deleteLoading ?

                            <Spinner animation="border" />
                            :
                            <>
                                <Button variant="secondary" onClick={handleClose2}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={deleteCode}>
                                    Delete
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
            {/* delete Model */}


            <EditPromo
                show={editModal}
                close={() => seteditModal(false)}
                reload={() => getCodes()}
                promoObject={selectedObject}
            />

        </div >
    );
};

export default GiveawayCodes;
