import { Formik, useFormik, Form, Field, } from "formik";
import React, { useState } from 'react'
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from '../../../services/config';
import toast from 'react-hot-toast'
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { Input } from '../../../_metronic/_partials/controls';

const EditVersion = ({ visible, hide, reload, selectedObject,foldable, isPublishable }) => {




    console.log(isPublishable);

    const authToken = localStorage.getItem("userToken");

    const [addLoading, setaddLoading] = useState(false)


    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };



    const initialValues = {
        version: selectedObject.version,


    };


    const LoginSchema = Yup.object().shape({
        version: Yup.string()
            .required('Version number is required')


    });





    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setaddLoading(true)

            const data = {
                version: values.version,
                publish: 0

            }
            console.log(data)
            // fetch(`${baseUrl}/admin/versions/${selectedObject.id}?_method=PUT`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Authorization: `Bearer ${authToken}`,
            //     },
            //     body: JSON.stringify(data)
            // }).then(res => res.json())
            //     .then((data) => {
            //         if (data.metadata.success) {
            //             toast.success(data.metadata.message);
            //             setSubmitting(false)
            //             reload()
            //             setaddLoading(false)
            //             hide()

            //         }
            //         else if (!data.metadata.success) {
            //             Object.values(data.metadata.message).map(msg => {
            //                 toast.error(msg)
            //                 setaddLoading(false)
            //                 setSubmitting(false)
            //             })
            //         }
            //         console.log(data)
            //     }).catch(err => {
            //         setSubmitting(false)
            //         setaddLoading(false)

            //         console.error("add codes error", err)
            //     })

        }
    })


    const EditHandler = (values) => {
        setaddLoading(true)

        const data = {
            version: values.version,
            publish: isPublishable ? 1 : 0

        }
        let url1=`admin/versions/${selectedObject.id}?_method=PUT`;
        let url2=`admin/fold-able-versions/${selectedObject.id}?_method=PUT`;
        fetch(`${baseUrl}/${foldable  ? url2 : url1}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message);

                    hide()
                    reload()
                    setaddLoading(false)

                }
                else if (!data.metadata.success) {
                    Object.values(data.metadata.message).map(msg => {
                        toast.error(msg)
                        setaddLoading(false)

                    })
                }
                console.log(data)
            }).catch(err => {

                setaddLoading(false)

                console.error("add codes error", err)
            })
    }




    return (
        <Modal
            show={visible}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Version Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    // validate={validatefield }
                    onSubmit={(values) => {
                        EditHandler(values)
                    }}
                >


                    {({ handleSubmit, handleChange, setValues, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => (

                        <Form autoComplete='off'>
                            <div className="row">
                                <div className="col-md-6 m-2">
                                    <label>Version Code</label>
                                    <Field
                                        name="version"
                                        className={`form-control form-control-solid h-auto py-2 px-3 ${getInputClasses(
                                            "version"
                                        )}`}
                                        value={values.version}
                                        component={Input}
                                        placeholder="Version Code"

                                    />
                                    {formik.touched.version && formik.errors.version ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.version}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <Modal.Footer>
                                <Button variant="danger" onClick={hide}>
                                    Close
                                </Button>
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    onClick={handleSubmit}
                                    className={`btn btn-primary`}
                                >
                                    <span style={{ textAlign: "center" }}>Update Code</span>
                                    {addLoading && <Spinner animation="border" />}
                                </button>
                            </Modal.Footer>
                        </Form>
                    )}


                </Formik>

            </Modal.Body>
        </Modal>
    )
}

export default EditVersion