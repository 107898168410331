
const INITIAL_STATE = {
    isAuthenticated: false,
    user: null,
    authtoken: null

};

const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_USER':
            console.log(action.payload)
            return {
                ...state,
                user: action.payload.user,
                isAuthenticated: action.payload.isAuthenticated,
                authtoken: action.payload.authtoken
            };

        default:
            return state;
    }
};

export default UserReducer;
