import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import Usercard from "../components/Usercard";

const Users = () => {







  return (
    <>

      <Usercard />


    </>
  );
};

export default Users;
