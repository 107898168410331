import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { baseUrl } from "../../services/config";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const Colormanagement = () => {


  const authToken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false)
  const [maintenance, setMaintenance] = useState(false)
  const [newmaintenance, setNewMaintenance] = useState(false)

  useEffect(() => {
    getColor()
  }, [])

  const getColor = () => {
    fetch(`${baseUrl}/admin/settings`, {
      method: "GET",
      headers: {

        Authorization: `Bearer ${authToken}`,
      },
    }).then(response => response.json())
      .then((data) => {
        console.log(data);
        setbgColor(data.payload.background_color)
        setcolor(data.payload.theme_color)
        settext_color(data.payload.text_color)
        setLoginCount(data.payload.login_count)
        setDiscount(data.payload.hd_wallpaper_discount)
        setad_view_count(data.payload?.ad_view_count)
        setpurchase_count(data.payload?.purchase_count)
        // setMaintenance(data.payload.maintenance == 0 ? false : true)

      }).catch(err => {
        console.log(err);
      })
  }



  const [color, setcolor] = useState('')
  const [bgColor, setbgColor] = useState('')
  const [text_color, settext_color] = useState('')
  const [loginCount, setLoginCount] = useState('')
  const [discount, setDiscount] = useState('')
  const [ad_view_count, setad_view_count] = useState('')
  const [purchase_count, setpurchase_count] = useState('')




  const UpdateColorofApp = () => {
    setloading(true)
    const data = {
      background_color: bgColor,
      theme_color: color,
      text_color: text_color,
      maintenance: maintenance ? 1 : 0,
      new_maintenance: newmaintenance ? 1 : 0,
      login_count: loginCount,
      hd_wallpaper_discount: discount,
      ad_view_count,
      purchase_count
    }
    console.log(data)
    fetch(`${baseUrl}/admin/settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        if (data.metadata.success) {
          toast.success(data.metadata.message)
          setloading(false)

        }
        else if (!data.metadata.success) {
          Object.values(data.metadata.message).map(msg => {
            toast.error(msg)
            setloading(false)
          })

        } else {
          toast.error('Something went wrong')
          setloading(false)
        }

        console.log(data)
      })
      .catch(error => {
        console.log(error);
        setloading(false)
      })
  }


  return (
    <div>
      <div style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "10px",

      }}>
        {loading ? <Spinner animation="border" />
          :
          <button onClick={UpdateColorofApp} className="btn btn-primary text-center">Save</button>
        }
      </div>
      <div className="row">

      <div className="col-md-6 col-lg-3">
        <label style={{fontSize: 18, fontWeight: '600'}} >
          Login Count
        </label>
        <input
          placeholder="Login Count" className="form-control "
          onChange={e => setLoginCount(e.target.value)}
          value={loginCount}

        />

      </div>
      <div className="col-md-6 col-lg-3">
        <label style={{fontSize: 18, fontWeight: '600'}} >
          Ad View Count
        </label>
        <input
          type="number"
          placeholder="View Count" className="form-control "
          onChange={e => setad_view_count(e.target.value)}
          value={ad_view_count}

        />

      </div>
      <div className="col-md-6 col-lg-3">
        <label style={{fontSize: 18, fontWeight: '600'}} >
          Daily Reward Device's Count
        </label>
        <input
          type="number"
          placeholder="Purchase Count" className="form-control "
          onChange={e => setpurchase_count(e.target.value)}
          value={purchase_count}

        />

      </div>
      <div className="col-md-6 col-lg-3">
        <label style={{fontSize: 18, fontWeight: '600'}} >
        Hd Wallpaper Discount 
        </label>
        <input
          type="number"
          placeholder="Hd Wallpaper Discount " className="form-control "
          onChange={e => setDiscount(e.target.value)}
          value={discount}

        />

      </div>
      </div>
      <div className="row mt-5">

        <div className="col-md-6 col-lg-4">
          <input
            type="color"
            value={color}
            onChange={(e) => setcolor(e.target.value)}
            style={{ width: "100%", border: 'none', height: "300px", borderRadius: 20 }}
          />

          <h3 className="text-center">Theme Color</h3>
        </div>

        <div className="col-md-6 col-lg-4">
          <input
            type="color"
            value={bgColor}
            onChange={(e) => setbgColor(e.target.value)}
            style={{ width: "100%", border: 'none', height: "300px", borderRadius: "20px" }}
          />

          <h3 className="text-center mt-2">Background Color</h3>
        </div>
        <div className="col-md-6 col-lg-4">
          <input
            type="color"
            value={text_color}
            onChange={(e) => settext_color(e.target.value)}
            style={{ width: "100%", border: 'none', height: "300px", borderRadius: "20px" }}
          />

          <h3 className="text-center mt-2">Text Color</h3>
        </div>
      </div>

      <div className="row p-5">
        <FormControlLabel
          control={<Checkbox checked={maintenance} onChange={(e) => setMaintenance(e.target.checked)} name="checkedA" />}
          label="Maintenance"
        />
      </div>
      <div className="row p-5">
        <FormControlLabel
          control={<Checkbox
            checked={newmaintenance}
            onChange={(e) => setNewMaintenance(e.target.checked)}
            name="checkedA" />}
          label="New Maintenance"
        />
      </div>



    </div>
  );
};

export default Colormanagement;
