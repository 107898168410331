import React, { useState, useEffect, useRef } from 'react'
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap'
import { TagsInput } from "react-tag-input-component";
import Upload from "../../../src/app/Images/upload.png";
import { baseUrl } from '../../services/config';
import { Paper } from '@material-ui/core'
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom'

const EditLiveWallpaper = (props) => {

    const history = useHistory();

    const tagRef = useRef();

    let id = props.match.params.id

    const authToken = localStorage.getItem("userToken");
    const [selected, setSelected] = useState([]);
    const [selectedunVisible, setSelectedunVisible] = useState([]);
    const [currentwallapaper, setcurrentwallapaper] = useState({})
    const [name, setname] = useState(currentwallapaper.name);
    const [loading, setloading] = useState(false)
    const [gif, setgif] = useState('')
    const [gif2, setgif2] = useState('')
    const [gifPre, setgifPre] = useState('')
    const [gifPre2, setgifPre2] = useState('')
    const [video, setvideo] = useState('')
    const [video2, setvideo2] = useState('')
    const [videoPre, setvideoPre] = useState('')
    const [videoPre2, setvideoPre2] = useState('')
    const [url_link, seturl_link] = useState(currentwallapaper.url_link);
  const [unlockGems,setUnlockGems]=useState('')
  const [isHdThumnail,setIsHdThumbnail]=useState('')


    const [product, setProduct] = useState({
        tags: [],
    });


    useEffect(() => {
        getCurrennt()
    }, [])


    const getCurrennt = async () => {
        setloading(true)
        await fetch(`${baseUrl}/admin/live-wallpapers/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                console.log(data)
                setcurrentwallapaper(data.payload)
                setname(data.payload.name)
                seturl_link(data.payload.url_link)
                setUnlockGems(data.payload?.gems_to_unlock)
                setIsHdThumbnail(data.payload.hd_thumbnail)
                // let tagsName1 = data.payload.visible_tags.map(item => item.name)
                // let tagsName2 = data.payload.invisible_tags.map(item => item.name)
                // setSelected(tagsName1)
                // setSelectedunVisible(tagsName2)
                let arr=[]
                let arr2=[]
                 data.payload.tags.visible.map((item)=>{
                     arr.push(item.name)
                })
                 data.payload.tags.invisible.map((item)=>{
                     arr2.push(item.name)
                })
                setSelected(arr)
                setSelectedunVisible(arr2)
                setloading(false)


            }).catch(err => {
                console.log(err);
                setloading(false)

            })
    }



    const updateWallpaper = () => {
        // fetch(`${baseUrl}/admin/live-wallpapers/${id}?_method=PUT`, {
        //     method: 'POST',
        //     headers: {
        //         Authorization: `Bearer ${authToken}`,
        //         'Content-Type': 'application/json',
        //     }
        const fdata = new FormData();
        fdata.append("name", name);
        fdata.append("url_link", url_link);

        if (gif) {

            fdata.append("thumbnail", gif);
        }
        if (video) {
            fdata.append("video", video);
        }

        if (gif2) {
            fdata.append("hd_thumbnail", gif2);
        }
        if (video2) {
            fdata.append("hd_video", video2);
        }
        if(unlockGems !=undefined){
            if(isHdThumnail !=null || gif2)
                fdata.append("gems_to_unlock", unlockGems);
        }
        
        for (let i = 0; i < selected.length; i++) {
            fdata.append('visible_tags[]', selected[i])
        }
        for (let i = 0; i < selectedunVisible.length; i++) {
            fdata.append('invisible_tags[]', selectedunVisible[i])
        }

        setloading(true);
        fetch(`${baseUrl}/admin/live-wallpapers/${id}?_method=PUT`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            body: fdata,
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.metadata.success) {

                    toast.success(data.metadata.message);
                    setloading(false)
                    history.push('/livewallpapers')
                } else if (data.metadata.success) {
                    Object.values(data.metadata.message).map(msg => {
                        toast.error(msg)
                        setloading(false)
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                setloading(false)

            });

    }




    const handleOnKeyUp = (e) => {

        if (e.keyCode == 13) {
            const tags = tagRef.current.value.split(",");
            tags.forEach((tag) => {
                setProduct((prev) => ({ ...prev, tags: [...prev.tags, tag] }));
            });
        }
    };


    const handleRemoveTag = (tag) => {
        setProduct((prev) => ({
            ...prev,
            tags: prev.tags.filter((t) => t !== tag),
        }));
    };


    console.log('===>', selected)
    return (
        <Paper


            style={{ padding: 20 }}
        >
            <div>
                <h2>Edit Live wallpapers</h2>
            </div>


            {loading == true ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div class="spinner-border text-dark  p-5 m-5 " role="status">
                        <span class="sr-only ">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                            type="text"
                            defaultValue={name}
                            placeholder="Name"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <Form.Label>Url</Form.Label>
                        <Form.Control
                            onChange={(e) => seturl_link(e.target.value)}
                            type="text"
                            placeholder="Url"
                            value={url_link}
                            defaultValue={url_link}
                        />
                    </Form.Group>
                    {/* <Form.Group className="mb-3 col-md-12">
                        <textarea
                            onKeyDown={handleOnKeyUp}
                            ref={tagRef}
                            placeholder="Seperate tags with commas..."
                            className='form-control'
                        >


                        </textarea>
                        {product.tags.map((tag) => (
                            <small key={tag} onClick={() => handleRemoveTag(tag)}>
                                {tag}
                            </small>
                        ))}
                    </Form.Group> */}
                    <Form.Group className="mb-3 col-md-12">
                    <Form.Label>Visible Tags</Form.Label>
                           <TagsInput
                            value={selected}
                            onChange={setSelected}
                            name="tags"
                            placeHolder="Enter visible tags"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12">
                    <Form.Label>Invisible Tags</Form.Label>
                        <TagsInput
                            value={selectedunVisible}
                            onChange={setSelectedunVisible}
                            name="tags"
                            placeHolder="Enter Invisible tags"
                        />
                    </Form.Group>



                    <div className="col-6">
                        <div
                            className="avatar-upload-input text-center "
                            style={{ marginTop: "20px" }}
                        >
                            {/* <img src={Upload} alt="img" /> */}
                            <h2 className="mt-3">Upload Thumbnail</h2>

                            <div className="avatar-edit-input">
                                <input

                                    accept="image/gif, image/jpeg, image/png"

                                    onChange={(e) => {
                                        setgifPre(URL.createObjectURL(e.target.files[0]))
                                        setgif(e.target.files[0])}}
                                    type={"file"}
                                />
                     
                            </div>
                            {gifPre ?
                    <img  style={{ height:'150px', width:'200px',marginTop:"20px" }} src={gifPre} alt="img" />
                    : null}
                        </div>

                    </div>

                    <div className="col-6">
                        <div
                            className="avatar-upload-input text-center "
                            style={{ marginTop: "20px" }}
                        >
                            {/* <img src={Upload} alt="img" /> */}
                            <h2 className="mt-3">Upload Video</h2>

                            <div className="avatar-edit-input">
                                <input
                                    accept="video/mp4,video/x-m4v,video/*"
                                    onChange={(e) => {
                                        setvideoPre(URL.createObjectURL(e.target.files[0]))
                                        setvideo(e.target.files[0])}}
                                    className="btn btn-base"
                                    style={{ textAlignLast: "center" }}
                                    type="file"
                                    id="imageUpload"

                                />
             
                            </div>
                            {
                    videoPre ?
                      <video
                        height={200}
                        width={200}
                        autoPlay={false}
                        style={{marginTop:"-40px"}}
                        controls
                        src={videoPre}> </video>
                      : null

                  }
                        </div>


                    </div>

          <div className="" style={{width:"100%",borderTop:"1px solid gray",paddingTop:"20px",marginTop:"10px"}}>
            <h2 style={{textAlign:"center"}}>Upload Hd wallpaper</h2>
            <div className='row'>
            <Form.Group style={{padding:"0px 24px"}} className="mb-3 col-md-12" controlId="formBasicEmail">
              <Form.Label>Gems To Unlock</Form.Label>
              <Form.Control
              value={unlockGems}
                onChange={(e) => setUnlockGems(e.target.value)}
                type="number"
                placeholder="Enter Gems"
              />
            </Form.Group>
            <div className="col-6">
                        <div
                            className="avatar-upload-input text-center "
                            style={{ marginTop: "20px" }}
                        >
                            {/* <img src={Upload} alt="img" /> */}
                            <h2 className="mt-3">Upload Hd Thumbnail</h2>

                            <div className="avatar-edit-input">
                                <input

                                    accept="image/gif, image/jpeg, image/png"

                                    onChange={(e) => {
                                        setgifPre2(URL.createObjectURL(e.target.files[0]))
                                        setgif2(e.target.files[0])}}
                                    type={"file"}
                                />
                     
                            </div>
                            {gifPre2 ?
                    <img  style={{ height:'150px', width:'200px',marginTop:"20px" }} src={gifPre2} alt="img" />
                    : null}
                        </div>

                    </div>

                    <div className="col-6">
                        <div
                            className="avatar-upload-input text-center "
                            style={{ marginTop: "20px" }}
                        >
                            {/* <img src={Upload} alt="img" /> */}
                            <h2 className="mt-3">Upload Hd Video</h2>

                            <div className="avatar-edit-input">
                                <input
                                    accept="video/mp4,video/x-m4v,video/*"
                                    onChange={(e) => {
                                        setvideoPre2(URL.createObjectURL(e.target.files[0]))
                                        setvideo2(e.target.files[0])}}
                                    className="btn btn-base"
                                    style={{ textAlignLast: "center" }}
                                    type="file"
                                    id="imageUpload"

                                />
             
                            </div>
                            {
                    videoPre2 ?
                      <video
                        height={200}
                        width={200}
                        autoPlay={false}
                        style={{marginTop:"-40px"}}
                        controls
                        src={videoPre2}> </video>
                      : null

                  }
                        </div>


                    </div>
            </div>
                    </div>

                </div>
            )}


            {/* <Button className='m-2' variant="secondary " onClick={hide}>
                Close
            </Button> */}
            <Button className='m-2' variant="primary" onClick={updateWallpaper}>
                Update
            </Button>

        </Paper>

    )
}

export default EditLiveWallpaper