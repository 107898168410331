/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { DropdownMenu2 } from "../../dropdowns";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../../../services/config";

export function MixedWidget1({ className }) {

  const authToken = localStorage.getItem("userToken");
  const [counter, setcounter] = useState({})




  useEffect(() => {
    getCounting()
  }, [])



  const getCounting = () => {
    fetch(`${baseUrl}/admin/dashboard`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      }
    }).then(response => response.json())
      .then((data => {
        setcounter(data.payload)
      })).catch(err => console.log(err))
  }










  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
    // <div className={`card card-custom bg-gray-100 ${className}`}>
    <>

      <div className="card-spacer ">
        <div className="row m-0">
          <div
            className="col-sm-12 col-md-5 bg-light-info py-10 rounded-xl mr-7 mb-7 "
            style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
          >
            <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
              ></SVG>
            </span>
            <NavLink className="menu-link text-info font-weight-bold font-size-h6 d-flex justify-content-center" to="/side">

              Total Banners
            </NavLink>
            <div className="orders text-info font-weight-bold font-size-h6" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "-30px" }}>
              {counter?.banners_count}</div>
          </div>

          <div
            className="col-sm-12 col-md-5 px-6 py-8 bg-light-primary  rounded-xl mb-7"
            style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
          >
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Home/Picture.svg"
                )}
              ></SVG>
            </span>
            <NavLink className="menu-link text-primary font-size-h6 mt-2 d-flex justify-content-center" to="/livewallpapers">
              Live Wallpapers
            </NavLink>
            <div className="orders text-primary  font-size-h6" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "-30px" }}>
              {counter?.live_wallpapers_count}
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div
            className="col-sm-12 col-md-5 px-6 py-8  bg-light-danger rounded-xl mr-7 mb-7"
            style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
          >
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Picture.svg")}
              ></SVG>
            </span>
            <NavLink className="menu-link text-danger  font-size-h6 mt-2 d-flex justify-content-center" to="/requestedwallpapers">

              Requested Wallpapers


            </NavLink>
            <div className="orders text-danger  font-size-h6" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "-30px" }}>
              {counter?.requested_wallpapers_count}
            </div>
          </div>
          <div
            className="col-sm-12 col-md-5 px-6 py-8  bg-light-warning rounded-xl mb-7"
            style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
          >
            <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/communication/Add-User.svg")}
              ></SVG>
            </span>
            <NavLink className="menu-link text-warning  font-size-h6 d-flex justify-content-center" to="/users">

              Users

            </NavLink>
            <div className="orders text-warning  font-size-h6" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "-30px" }}>
              {counter?.users_count}
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div
            className="col-sm-12 col-md-5 px-6 py-8  bg-light-secondary rounded-xl mr-7"
            style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
          >
            <span className="svg-icon svg-icon-3x svg-icon-secondary d-block my-2 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Picture.svg")}
              ></SVG>
            </span>
            <NavLink className="menu-link text-secondary  font-size-h6 mt-2 d-flex justify-content-center" to="/Promocodes">

              Promo Codes

            </NavLink>
            <div className="orders text-secondary  font-size-h6" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "-30px" }}>
              {counter?.promo_codes_count}
            </div>
          </div>
          <div
            className="col-sm-12 col-md-5 py-8 mt-5 bg-light-success rounded-xl"
            style={{ boxShadow: "0rem 0rem 1rem #a1a1a1" }}
          >
            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/communication/Add-User.svg")}
              ></SVG>
            </span>
            <NavLink className="menu-link text-success  font-size-h6 d-flex justify-content-center" to="/settings">

              Color Management

            </NavLink>
            <div className="orders text-success  font-size-h6" style={{ display: "flex", flexDirection: "row-reverse", marginTop: "-30px" }}>2</div>
          </div>

        </div>







      </div>

    </>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40]
      }
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function (val) {
          return "$" + val + " thousands";
        }
      },
      marker: {
        show: false
      }
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}
