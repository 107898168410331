import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { baseUrl } from '../../../services/config'
import toast from 'react-hot-toast'


const ResetPassword = ({ visible, hideModal, userId, }) => {

    console.log(userId)
    const authToken = localStorage.getItem("userToken");

    const [loading, setLoading] = useState(false)


    const resetPasswordUser = () => {
        setLoading(true)
        fetch(`${baseUrl}/admin/users/${userId}/password-reset`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then(data => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message);
                    setLoading(false)
                    hideModal()

                } else if (!data.metadata.success) {
                    toast.error(data.metadata.message);
                    setLoading(false)
                }
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })

    }









    return (
        <Modal

            show={visible}
        // onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span>Do you want to reset that user password</span>
            </Modal.Body>
            <Modal.Footer>
                {
                    loading ? <Spinner animation='border' />
                        :
                        <>

                            <button
                                onClick={resetPasswordUser}
                                className='btn btn-primary'>
                                Reset
                            </button>
                            <button
                                onClick={hideModal}
                                className='btn btn-danger'>
                                Close
                            </button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ResetPassword