import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config'

const CancelModal = ({
    HideModal,
    visible,
    id,
    reload
}) => {

    const authToken = localStorage.getItem("userToken");
    const [loading, setLoading] = useState(false)

    const cancelSub = () => {
        setLoading(true)
        fetch(`${baseUrl}/admin/users/${id}/cancel-subscription`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {

                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    setLoading(false)
                    HideModal()
                    reload()
                } else if (!data.metadata.success) {
                    setLoading(false)
                    toast.error(data.metadata.message)
                } else {
                    setLoading(false)
                    toast.error('Unknown error :(')
                }
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }



    return (
        <Modal show={visible}>
            <Modal.Header>
                <Modal.Title>Cancel Subscription</Modal.Title>
            </Modal.Header>
            {
                loading ?
                    <Spinner animation='border' color='green' />
                    :
                    <>
                        <Modal.Body>
                            Are you sure you want to Cancel Subscription?
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={HideModal}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                                onClick={cancelSub}
                                className='btn btn-primary'>
                                Cancel Subscription
                            </button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
    )
}

export default CancelModal