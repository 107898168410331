import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
const EditPromo = ({ show, close, promoObject, reload }) => {

    const [addLoading, setaddLoading] = useState(false)
    const authToken = localStorage.getItem("userToken");

    const initialValues = {
        count: promoObject.count,
        gems: promoObject.gems,
        multiple: promoObject.multiple == 0 ? false : true,
        expiry: ""
    };


    const LoginSchema = Yup.object().shape({
        count: Yup.string()
            .required('Count is required'),
        gems: Yup.string()
            .required('Gems is required'),
        multiple: Yup.string()
            .notRequired('multiple is required'),
        expiry: Yup.string()
            .required('Expiry Date  is required')

    });



    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };


    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setaddLoading(true)

            //   const data = {
            //     count: values.count,
            //     gems: values.gems,
            //     multiple: values.multiple ? 1 : 0,
            //     expiry: values.expiry
            //   }
            //   fetch(`${baseUrl}/admin/promo-codes`, {
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json',
            //       Authorization: `Bearer ${authToken}`,
            //     },
            //     body: JSON.stringify(data)
            //   }).then(res => res.json())
            //     .then((data) => {
            //       if (data.metadata.success) {
            //         toast.success(data.metadata.message);
            //         setSubmitting(false)
            //         getCodes()
            //         setaddLoading(false)
            //         handleClose()

            //       }
            //       else if (!data.metadata.success) {
            //         Object.values(data.metadata.message).map(msg => {
            //           toast.error(msg)
            //           setaddLoading(false)
            //           setSubmitting(false)
            //         })
            //       }
            //       console.log(data)
            //     }).catch(err => {
            //       setSubmitting(false)
            //       setaddLoading(false)

            //       console.error("add codes error", err)
            //     })

        }
    })



    return (
        <Modal
            size="lg"
            show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Update Promo Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="row">
                        <div className="col-md-4">
                            <label>Select Count</label>
                            <select
                                name="count"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "count"
                                )}`}
                                placeholder="Select Count"

                                {...formik.getFieldProps("count")}
                            >
                                <option value>Select Count</option>
                                <option value="2">2</option>
                                <option value="4">4</option>
                                <option value="6">6</option>
                                <option value="8">8</option>
                                <option value="10">10</option>
                            </select>
                            {formik.touched.count && formik.errors.count ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.count}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-4">
                            <label>Select Gems</label>
                            <select
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "gems"
                                )}`}
                                name="gems"
                                placeholder="Select Gems"
                                {...formik.getFieldProps("gems")}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            {formik.touched.gems && formik.errors.gems ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.gems}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-md-4 mt-3">

                            <label>Select Type</label>
                            <select
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "multiple"
                                )}`}
                                name="multiple"
                                placeholder="Select Type"
                                {...formik.getFieldProps("multiple")}
                            >
                                <option value="Limited Codes">Limited Codes</option>
                                <option value="Giveaway Codes">Giveaway Codes</option>
                   
                            </select>
                            {formik.touched.multiple && formik.errors.multiple ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.multiple}</div>
                                </div>
                            ) : null}
                            {formik.touched.multiple && formik.errors.multiple ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.multiple}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <label>Expiry Date</label>
                            <input name="expiry" type="date" className={`form-control form-control-solid py-5 px-6 h-auto ${getInputClasses(
                                "multiple"
                            )}`}
                                {...formik.getFieldProps("expiry")}
                            />
                            {formik.touched.expiry && formik.errors.expiry ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.expiry}</div>
                                </div>
                            ) : null}

                        </div>


                    </div>

                    <Modal.Footer>
                        <Button variant="danger" onClick={close}>
                            Close
                        </Button>
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-primary`}
                        >
                            <span style={{ textAlign: "center" }}>Update</span>
                            {addLoading && <Spinner animation="border" />}
                        </button>
                    </Modal.Footer>
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default EditPromo