import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Spinner } from "react-bootstrap";
import { Avatar } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
// import Upload from "../../../../app/Images/upload.png";
import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../../services/config";
import ResetPassword from "./ResetPassword/ResetPassword";
import EditUser from "./EditUser/EditUser";
import UsePagination from "./UsePagination";
import { Switch } from '@material-ui/core';
import DeleteModal from "./DeleteModal/DeleteModal";

const Usercard = () => {
  useEffect(() => {
    getUsers(`${baseUrl}/admin/search-users`);
  }, []);

  const authToken = localStorage.getItem("userToken");

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetPasswordModal, setresetPasswordModal] = useState(false);
  const [links, setlinks] = useState([]);
  const [numberofpages, setnumberofpages] = useState(0);
  const [total, settotal] = useState(0);
  const [searchText, setsearchText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [nameText, setNameText] = useState("");
  const [userId, setuserId] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [editModal, seteditModal] = useState(false);
  const [userObject, setuserObject] = useState({});
  const [gemsTextSearch, setGemsTextSearch] = useState(false)


  const [selectedUserId, setSelectedUserId] = useState(null)
  const [deleteModel, setDeleteModel] = useState(false)

  const getUsers = (url, sortgem) => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        first_name: nameText,
        sort_by_gems: sortgem ? 1 : 0,

      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.payload.data);
        setnumberofpages(data.payload.per_page);
        settotal(data.payload.total);
        setlinks(data.payload.links);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onPaginate = (link) => {
    let checkb = gemsTextSearch ? 1 : 0
    getUsers(link?.url, checkb);
  };

  const openResetModal = (id) => {
    setselectedId(id);
    setresetPasswordModal(true);
  };

  const dataSearch = users.filter((user) => {
    let text = searchText.toLowerCase();
    return searchText
      ? user?.first_name?.toLowerCase().includes(text) ||
      user?.last_name?.toLowerCase().includes(text) ||
      user?.email?.toLowerCase().includes(text)
      : true;
  });

  const handleChange = (event) => {
    setGemsTextSearch(event.target.checked)
    getUsers(`${baseUrl}/admin/search-users`, event.target.checked)
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const data = {
      first_name: nameText,
      email: emailText,
      sort_by_gems: gemsTextSearch,
      user_id: userId,

    };
    fetch(`${baseUrl}/admin/search-users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          console.log(data);
          setUsers(data.payload.data);
          setnumberofpages(data.payload.per_page);
          settotal(data.payload.total);
          setlinks(data.payload.links);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };




  const openDeleteModal = (id) => {
    setSelectedUserId(id)
    setDeleteModel(true)
  }



  return (
    <div>
      <Paper style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h3>All Users</h3>
        </div>
        <hr style={{ marginLeft: "-20px", marginRight: "-20px" }} />

        <form className="form-label-right" onSubmit={(e) => handleSearch(e)}>
          <div className="form-group row">
            {/* <Form.Group className="mb-3 col-md-3" controlId="formBasicEmail">
              <Form.Label>THLN</Form.Label>
              <Form.Control type="text" placeholder="THLN" />
            </Form.Group> */}

            <div className="col-md-2 mt-5">
              <Form.Label>Sort by gems</Form.Label>
              <Switch
                checked={gemsTextSearch}
                onChange={handleChange}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <div className="col-md-2">
              <Form.Label>Search by User Id</Form.Label>
              <input
                onChange={(e) => setuserId(e.target.value)}
                type="text"
                className="form-control"
                name="searchText"
                placeholder="User Id"
              />
            </div>
            <div className="col-md-2">
              <Form.Label>Search by Name </Form.Label>
              <input
                onChange={(e) => setNameText(e.target.value)}
                type="text"
                className="form-control"
                name="searchText"
                placeholder="Search "
              />

              {/* <small className="form-text text-muted">
                <b>Search</b> in all fields
              </small> */}
            </div>
            <div className="col-md-3">
              <Form.Label>Search by Email </Form.Label>
              <input
                onChange={(e) => setEmailText(e.target.value)}
                type="text"
                className="form-control"
                name="searchText"
                placeholder="Search "
              />

              {/* <small className="form-text text-muted">
                <b>Search</b> in all fields
              </small> */}
            </div>
            <div className="col-md-3 mt-8">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>

        <Table responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Image</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Gems</th>
              <th>Edit</th>
              <th>Reset Password</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {dataSearch &&
              dataSearch.map((user, index) => (
                <tr key={user.id}>
                  <td>
                    {user.id}
                  </td>
                  <td style={{ verticalAlign: "center" }}>
                    <Avatar
                      alt={user.first_name}
                      src={user?.avatar?.original_url}
                    />
                  </td>
                  <td style={{ verticalAlign: "center" }}>
                    {user.first_name + user.last_name}
                  </td>
                  <td style={{ verticalAlign: "center" }}>{user.email}</td>
                  <td style={{ verticalAlign: "center" }}>{user.phone}</td>
                  <td style={{ verticalAlign: "center" }}>{user.gems}</td>

                  <td>
                    <a
                      onClick={() => {
                        setuserObject(user);
                        seteditModal(true);
                      }}
                      style={{ verticalAlign: "center" }}
                      className="btn btn-icon btn-light btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                  <td onClick={() => openResetModal(user.id)}>
                    <a
                      style={{ verticalAlign: "center" }}
                      className="btn btn-icon btn-light btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                  <td onClick={() => openDeleteModal(user.id)}>
                    <a
                      style={{ verticalAlign: "center" }}
                      className="btn btn-icon btn-light btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                        src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {loading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" />
          </div>
        )}

        <UsePagination links={links} onPaginate={onPaginate} />
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          {
            links.map((link, index) => (
              <button
                className={`btn m-2 ${link.active ? 'btn-primary' : 'btn-info'}`}
                disabled={link.url == null || link.active ? true : false}
                onClick={() => onPaginate(link)}>
                <div>{link.label == 'Next &raquo;' ? 'Next' : link.label == "&laquo; Previous" ? 'Previous' : link.label}</div>
              </button>
            ))
          }
        </div> */}
      </Paper>

      <ResetPassword
        visible={resetPasswordModal}
        userId={selectedId}
        hideModal={() => setresetPasswordModal(false)}
      />

      <EditUser
        reload={() => getUsers(`${baseUrl}/admin/search-users`, false)}
        visible={editModal}
        userObject={userObject}
        hideModal={() => seteditModal(false)}
      />

      <DeleteModal
        visible={deleteModel}
        HideModal={() => setDeleteModel(false)}
        id={selectedUserId}
        reload={() => getUsers(`${baseUrl}/admin/search-users`, false)}
        method={'DELETE'}
        url={`${baseUrl}/admin/users/${selectedUserId}`}


      />
    </div>
  );
};

export default Usercard;
