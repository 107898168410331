import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import "./Side.css";
import Imagecard from "../components/Imagecard";
import { baseUrl } from "../../services/config";
import Updatecomponent from "../components/Updatecomponent";
import { toast } from "react-hot-toast";
import Promotionslider from "../components/Promotionslider";
import AddWallpaperModal from "../components/AddWallpaperModal/AddWallpaperModal";

const Promotion = ({ foldable }) => {
  const authToken = localStorage.getItem("userToken");

  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // if(wallapersList.length >= 2){
    //   toast.error("You Can Just Select 2 Side banners");
    // }
    // else{
    setShow(true);
    // }
  }


  const [wallapersList, setwallapersList] = useState([]);


  const [updateModal, setupdateModal] = useState(false);
  const [selectedObject, setselectedObject] = useState({});

  const handleChangeImages = (e) => {
    let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";
    // for (let i = 0; i < e.target.files.length; i++) {
    //     imagesArray.push(e.target.files[i]);
    // }
    imagesArray.push(img);
    setimages(imagesArray);
    console.log(imagesArray);
  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    fileObj.push(event.target.files[0]);
    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }
    // console.log("file array",fileArray)
    // console.log("file obj",fileObj)
  };

  const [startDate, setStartDate] = useState(new Date());

  const [showlist, setshowlist] = useState([]);
  const [images, setimages] = useState([]);

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });
    setimages(newArr2);
    setshowlist(newArr);
  };

  useEffect(() => {
    getSideWallapers();
  }, [foldable]);

  const getSideWallapers = () => {

    let url1 = "admin/banners/index/promotion";
    let url2 = "admin/fold-able-banners/index/promotion";
    fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setwallapersList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [img, setimg] = useState("");

  //start save api
  const Homesave = () => {
    const fdata = new FormData();
    fdata.append("type", "promotion");
    fdata.append("url", url);
    fdata.append("image", img);

    // Axios.post(`${baseUrl}/admin/banners`, fdata, {
    //   Authorization: `Bearer 13|1zboAQruugqNf2Mbny3IqQW6RGnTxwJ9jXrngKQG`,
    // })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    setloading(true)
    fetch(`${baseUrl}/admin/banners`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          setloading(false);
          setShow(false);
          getSideWallapers();
        }
        else {
          setloading(false);
          setShow(false);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  //end save api
  const editWallpaper = (card) => {
    setselectedObject(card);
    setupdateModal(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Button
          // title={wallapersList.length >= 2 ? "You can just 3 banners" : ""}
          // disabled={wallapersList.length >= 2 ? true : false}
          className="mr-2 mb-2"
          variant="primary"
          onClick={handleShow}
        >
          Add New
        </Button>
      </div>
      <div className="row">
        {wallapersList &&
          wallapersList.map((item) => (
            <Imagecard
              foldable={foldable}

              onEditClick={(card) => editWallpaper(card)}
              reload={() => getSideWallapers()}
              card={item}
              title="Promotion"
            />
          ))}
      </div>


      <Updatecomponent
        foldable={foldable}
        type={"promotion"}
        url={selectedObject.url}
        show={updateModal}
        hide={() => setupdateModal(false)}
        reload={() => getSideWallapers()}
        title="Promotion"
        wallpaperobject={selectedObject}
      />
      <AddWallpaperModal
        foldable={foldable}
        visible={show}
        type="promotion"
        handleCloseModal={() => setShow(false)}
        reload={() => getSideWallapers()}

      />

    </>
  );
};

export default Promotion;
