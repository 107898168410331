
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export const push_key = 'BH9siCzz7BXqKdbsZ8CMLajjruTyKjIKfDGAXQx-O1WnGvYTYyoWgCRTPLSPN8N4c6OqLD2B7VqlEIiaRfibZ3M'

export const firebaseConfig = {
    apiKey: "AIzaSyB7NbJKrrZMvaBnwwgfl7M5-rCJw7pRNQo",
    authDomain: "epicwallpapers-38f95.firebaseapp.com",
    databaseURL: "https://epicwallpapers-38f95.firebaseio.com",
    projectId: "epicwallpapers-38f95",
    storageBucket: "epicwallpapers-38f95.appspot.com",
    messagingSenderId: "43706229938",
    appId: "1:43706229938:web:3682ed9e343f9dc91755ab",
    measurementId: "G-TETZ2S2459"
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getTokenfirebase = (setTokenFound) => {
    return getToken(messaging, { vapidKey: push_key }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
