import React, { useState, Component } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Upload from "../../../src/app/Images/upload.png";
import './style.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Link } from "react-router-dom";
import image from "../../../src/app/Images/girl.jpg";

// import "../../Maincatogry.css";

import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";

import Modal from "react-bootstrap/Modal";
import "../../../src/app/pages/Side.css";
import { baseUrl } from "../../services/config";
import { toast } from "react-hot-toast";

const Requestedwallpapercard = ({ requestedCard, reload, onItemCheck }) => {




  console.log(requestedCard);
  const authToken = localStorage.getItem("userToken");

  const [loading, setloading] = useState(false);

  const [rating, setRating] = useState('')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [updateModal, setUpdateModal] = useState(false)


  const onChangeStatus = (status) => {
    // console.log(status);
    setloading(true);
    fetch(`${baseUrl}/admin/requested-wallpapers/${status}/toggle-approve`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          setloading(false);
          setShow(false)
          setShow2(false)
          sendNotification()
          reload();
          toast.success("Status Change Successfully");
        } else {
          setloading(false);
        }
        console.log(data);
      })
      .catch((err) => {
        setloading(false);

        console.log(err);
      });
  };
  // const underdevelopment = () => {
  //   toast.error("Under Development");
  // };


  const sendNotification = async () => {
    const notification = {
      body: 'Your Request is Approved',
      title: 'Wallpapers Status Changed',
    }

    const body = {
      title: "Wallpapers Status Changed",
      body: "Your Request is Approved"
    }
    const fdata = {
      notification: notification,
      data: body,
      to: requestedCard?.user?.device?.device_id,
    }

    await fetch("https://fcm.googleapis.com/fcm/send", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "key=AAAACi0YJLI:APA91bH8mpgnhlkZQQ4indjxX6cyH2-bu5deME-pkTnztyIaUOrkSDd3X7K_ZiUkP0dQNTDfNifCrZonmf87sbfZEycST9I6HrBbx4tAO1loTt1ZaLaGCPLlg3WCAYDWTv3KoYGrLSS0",
      },
      method: "POST",
      body: JSON.stringify(fdata),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response of notifications===>", data);

      })
      .catch((error) => {
        console.log("notification error====>", error);
      });
  }




  const UpdateRating = () => {


    fetch(`${baseUrl}/admin/update-rating-requested-wallpapers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify({
        requested_wallpaper_id: requestedCard.id,
        rating
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.metadata.success) {
          toast.success(data.metadata.message)
          setUpdateModal(false)
          setRating('')
          reload()
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map(msg => {
            toast.error(msg)
          })
        }
      })
      .then(err => {
        console.log(err)
      })

  }



  return (
    <>

      <div class="col-lg-2 mb-5 repsonsive-media" style={{ width: "280px" }}>
        {/* <input type={'checkbox'} /> */}
        <div class="card" style={{ borderRadius: "20px" }}>
          
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',

              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              backgroundColor: 'rgba(0,0,0,0.5)',
              height: 40,
              width: '100%'
            }}
          >

            <label class="check">
              <input
                //  className="form-check-input"
                id="rowcheck{user.id}"
                onChange={(e) => onItemCheck(e, requestedCard)}
                checked={requestedCard.selected}
                type="checkbox"
              />
              <div class="box"></div>
            </label>
            <span style={{ color: 'yellow' }}>
              {requestedCard.rating}
            </span>
            <a target="_blank" href={requestedCard?.image?.original_url} style={{ width: 38, height: 38, borderTopRightRadius: 8, marginTop: -4, }} className="btn btn-icon btn-light btn-sm">
              <div className="svg-icon svg-icon-md svg-icon-danger mr-4 d-flex justify-content-center align-items-center">
                <SVG style={{ marginLeft: "13px" }}
                  // style={{ width: 170 }}
                  src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
                ></SVG>
              </div>
            </a>
          </div>

          <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 40,
            width: '100%'
          }}>
            <span style={{ color: 'white' }}>{requestedCard?.approved == 0 ? 'Not Approved' : requestedCard?.approved == 1 ? 'Approved' : ''}</span>
          </div>
          <div
            onClick={() => {
              setRating(requestedCard.rating)
              setUpdateModal(true)
            }}
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 100,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#B2295A',
              height: 40,
              width: '100%'
            }}>
            <button className="btn" style={{ color: 'white' }}>Edit Rating</button>
          </div>
          <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 160,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 40,
            width: '100%'
          }}>
            <span style={{ color: 'white' }}>{requestedCard?.name}</span>

          </div>
          <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 200,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 40,
            width: '100%'
          }}>

            <span style={{ color: 'white' }}>{requestedCard?.character}</span>

          </div>
          <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 230,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 40,
            width: '100%'
          }}>
            <span className="text-center" style={{ color: 'white' }}>{requestedCard?.animation_guidance}</span>

          </div>
          <div style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 270,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            height: 40,
            width: '100%'
          }}>
            <span className="text-center" style={{ color: '#B2295A' }}>User Id:{requestedCard?.user?.id}</span>

          </div>
          <img
            height={300}
            loading="lazy"
            style={{
              borderStartStartRadius: "10px",
              borderTopRightRadius: "10px",
            }}
            src={requestedCard.image?.original_url}
            alt="image 1"
          />

          <div
            style={{
              backgroundColor: "white",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {requestedCard?.approved == 0 ? (
                <button
                  onClick={() => setShow(true)}

                  className="btn btn-success"
                  style={{ width: "100px" }}
                >
                  Approve
                </button>
              ) : requestedCard?.approved == 1 ? (
                <button

                  onClick={() => setShow2(true)}

                  className="btn btn-danger"
                  style={{ width: "100px" }}
                >
                  Reject
                </button>
              ) : null}
              {/* <button
                onClick={() => setShow(true)}

                className="btn btn-success"
                style={{ width: "100px" }}
              >
                Download
              </button> */}
            </div>
          </div>

        </div>



        {/* Reject modal start */}
        <Modal
          show={show2}
          onHide={handleClose2}
          animation={false}
          size="md"
          // style={{ marginLeft: "140px" }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Reject</Modal.Title>
          </Modal.Header>
          {loading == true ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div class="spinner-border text-dark  p-5 m-5 " role="status">
                <span class="sr-only ">Loading...</span>
              </div>
            </div>
          ) : (
            <Modal.Body className="row">
              <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                <h3 className="text-center">Do you want to Rejected </h3>

              </Form.Group>


            </Modal.Body>
          )}

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="danger" onClick={() => onChangeStatus(requestedCard.id)}>
              Reject
            </Button>
          </Modal.Footer>
        </Modal>
        {/*Reject  modal end */}





        {/* Approve modal start */}
        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          size="md"
          // style={{ marginLeft: "140px" }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Approve</Modal.Title>
          </Modal.Header>
          {loading == true ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div class="spinner-border text-dark  p-5 m-5 " role="status">
                <span class="sr-only ">Loading...</span>
              </div>
            </div>
          ) : (
            <Modal.Body className="row">
              <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                <h3 className="text-center">Do you want to Approved </h3>

              </Form.Group>


            </Modal.Body>
          )}

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" onClick={() => onChangeStatus(requestedCard.id)}>
              Approve
            </Button>
          </Modal.Footer>
        </Modal>
        {/*Approve  modal end */}

      </div>
      {/* )} */}

      <Modal
        show={updateModal}
      >
        <Modal.Header>
          <Modal.Title>Update Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            value={rating}
            onChange={(e) => setRating(e.target.value)}
            className="form-control" />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setUpdateModal(false)} className="btn btn-danger">Close</button>
          <button onClick={UpdateRating} className="btn btn-success">Update</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Requestedwallpapercard;

