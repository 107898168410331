import React, { useState, Component } from "react";
import SVG from "react-inlinesvg";
import "react-datepicker/dist/react-datepicker.css";
import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import "./Livewallpapercard.css";
import { baseUrl } from "../../services/config";
import { toast } from "react-hot-toast";
import EditLiveWallpaper from "./EditLiveWallpaper";
import PreviewVideo from "./PreviewVideo/PreviewVideo";
import { useHistory } from 'react-router-dom'

const Livewallpapercard = ({ Livecard, reload }) => {


  const history = useHistory();
  const authToken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [selectedWallpaper, setselectedWallpaper] = useState({})

  const [previewModal, setpreviewModal] = useState(false)
  const [editModal, seteditModal] = useState(false)


  const deletelivewallpaper = () => {
    // setloading(true)
    fetch(`${baseUrl}/admin/live-wallpapers/${Livecard.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setloading(false);
          reload();
        }
        // else{
        //   setloading(false)
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditWallpaper = (id) => {
    setselectedWallpaper(Livecard)
    seteditModal(true)
  };



  const goToEdit = () => {

    history.push({
      pathname: `/editlivewallpaper/${Livecard.id}`,
      state: Livecard.tags
    })

  }





  return (
    <>
      {loading == true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="spinner-border text-dark  p-5 m-5 " role="status">
            <span class="sr-only ">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="col-md-2 mb-5">
          <div
            class="livewallpapercard"
            style={{
              backgroundImage: `url(${Livecard?.thumbnail?.original_url})`,
            }}
          >



            <div class="livewallpapecontent">
              {/* <h5>Live Wallpapers</h5> */}
              <div class="liveicons">
                <div className="p-3">
                  <button
                    onClick={() => setpreviewModal(true)}
                    className="btn btn-icon btn-dark btn-sm"
                  >
                    <span

                      className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/visible.svg"
                        )}
                      ></SVG>
                    </span>
                  </button>
                </div>

                <div className="p-3">
                  <button
                    onClick={() => goToEdit()}

                    className="btn btn-icon btn-dark btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      ></SVG>
                    </span>
                  </button>
                </div>

                <div onClick={deletelivewallpaper} className="p-3">
                  <a className="btn btn-icon btn-dark btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      ></SVG>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>



      )}



      <PreviewVideo
        videoUrl={Livecard?.video?.original_url}
        visible={previewModal}
        hideModal={() => setpreviewModal(false)}

      />


    </>
  );
};

export default Livewallpapercard;
