import React from 'react'
import { Modal } from 'react-bootstrap'
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';
const PreviewVideo = ({ visible, hideModal, videoUrl, }) => {

    return (
        <Modal
            size="lg"
            show={visible}
            onHide={hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Preview Video</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <video
                    height={400}
                    width={'100% 100%'}
                    src={videoUrl}
                    controls
                    autoPlay

                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    className='btn btn-danger'>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default PreviewVideo