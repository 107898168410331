import React, { useState, Component } from "react";
import SVG from "react-inlinesvg";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import { toAbsoluteUrl } from "../../../src/_metronic/_helpers";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../../services/config";

const Imagecard = ({ card, reload, onEditClick, title, height,foldable, currentIndex }) => {
  const authToken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => {

  // if(wallapersList.length >= 2 ){
  //   toast.error("You Can Just Select 2 Side banners");
  // }
  // else{
  //   setShow(true)
  // }

  // }

  

  const deleteImage = () => {
    setloading(true);
let url1 = `admin/banners/${card.id}`;
let url2 = `admin/fold-able-banners/${card.id}`;


    fetch(`${baseUrl}/${foldable  ? url2 : url1}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false);
          setShow(false);
          reload();
        }
        // else{
        //   setloading(false)
        // }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="col-md-12 mt-5">
      {/* {
        loading == true ? (
          <div style={{display:"flex" , alignItems:"center" , justifyContent:"center"}}>
          <div class="spinner-border text-dark  p-5 m-5 "  role="status">
            <span class="sr-only " >Loading...</span>
          </div>
          </div>
        ):
        ( */}

      <div
        class="SidecardBox"
        style={{
          height: height,
          backgroundImage: `url(` + `${card?.image?.original_url}` + `)`,
        }}
      >
        <div class="Sidecard">
          <div class="Sidecontent">
            <div className="hover-icons">
              {
                currentIndex == 0 ? null :
                  <div className="p-3">
                    <a
                      href={card.url}
                      target={"_blank"}
                      className="btn btn-icon btn-dark btn-sm"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-success">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/visible.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                  </div>
              }
              <div onClick={() => onEditClick(card)} className="p-3">
                <a className="btn btn-icon btn-dark btn-sm">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    ></SVG>
                  </span>
                </a>
              </div>
              <div onClick={() => setShow(true)} className="p-3">
                <a className="btn btn-icon btn-dark btn-sm">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    ></SVG>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}

      {/* delete modal start */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {loading == true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="spinner-border text-dark  p-5 m-5 " role="status">
              <span class="sr-only ">Loading...</span>
            </div>
          </div>
        ) : (
          <Modal.Body className="row">
            <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
              <h3 className="text-center">
                Are you sure ? you want to delete it
              </h3>
            </Form.Group>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteImage}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/*delete modal end */}
    </div>
  );
};

export default Imagecard;
