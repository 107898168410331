import { Paper } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { baseUrl } from '../../../services/config'
import { Modal, Spinner, Table } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import SVG from "react-inlinesvg";
import { toast } from 'react-hot-toast'


const Rewards = () => {
    const authToken = localStorage.getItem("userToken");

    const [selectedObject, setSelectedObject] = useState({})
    const [editModal, seteditModal] = useState(false)
    const [rewardsList, setRewardsList] = useState([])
    const [gems, setGems] = useState('')
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        getRewards()
    }, [])


    const getRewards = () => {
        fetch(`${baseUrl}/admin/rewards`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                setRewardsList(data.payload)
            })
            .catch(err => {
                console.log(err)
            })
    }





    const onEditClick = (item) => {
        setSelectedObject(item)
        seteditModal(true)
    }



    const updateReward = () => {

        const data = {
            gems: gems,
            // day: selectedObject.id
        }
        setisLoading(true)
        fetch(`${baseUrl}/admin/rewards/${selectedObject.id}?_method=PUT`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success('Rewards updated successfully')
                    setisLoading(false)
                    seteditModal(false)

                } else if (!data.metadata.success) {
                    Object.values(data.metadata.message).map(msg => {
                        toast.error(data.metadata.message)
                        setisLoading(false)

                    })
                } else {
                    setisLoading(false)
                    toast.error('server error')
                }
            })
            .catch(err => {
                console.log(err)
                setisLoading(false)

            })
    }




    return (
        <Paper className='p-3'>

            <Table>
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>Gems</th>
                        <th>Update</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        rewardsList.map((rewrd => (
                            <tr>
                                <td>{rewrd.day}</td>
                                <td>{rewrd.gems}</td>
                                <td
                                    onClick={() => onEditClick(rewrd)}
                                    style={{ cursor: 'pointer' }}>
                                    <span className="svg-icon svg-icon-md svg-icon-danger"
                                    // onClick={() => handleShow2(item.id)}
                                    >
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
                                        ></SVG>
                                    </span>
                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </Table>

            <Modal
                show={editModal}
                animation
            >
                <Modal.Header>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='col-md-12'>

                        <input className='form-control' defaultValue={selectedObject.day} disabled />
                    </div>
                    <div className='col-md-12 mt-3'>
                        <input defaultValue={selectedObject.gems} className='form-control' onChange={(e) => setGems(e.target.value)} placeholder='Enter Gems' />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {
                        isLoading ? <Spinner animation='border' />
                            :
                            <>
                                <button onClick={() => seteditModal(false)} className='btn btn-danger'>Cancel</button>
                                <button onClick={updateReward} className='btn btn-success'>Update</button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </Paper>
    )
}

export default Rewards