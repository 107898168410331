import React, { useState } from 'react'
import { baseUrl } from '../../../services/config';
import SendNotification from '../Send-Notification/SendNotification'

const NotificationSend = () => {

    const [show, setshow] = useState(true)


    const authToken = localStorage.getItem("userToken");
    const [list, setlist] = useState([])
  
  
    React.useEffect(() => {
  
      fetch(`${baseUrl}/admin/users/device-id`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      }).then(res => res.json())
        .then(data => {
          if (data.metadata.success) {
            setlist(data.payload.device_ids)
  
          }
        }).catch(err => {
          console.log("error: " + err)
        })
  
    }, [])
  
    return (
        <div>
            <SendNotification
                listokens={list}
                show={show}
                hideModal={() => setshow(false)}
            />
        </div>
    )
}

export default NotificationSend