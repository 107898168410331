import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import Side from "./pages/Side";
import Social from "./pages/Social";
import Promotion from "./pages/Promotion";
import Request from "./pages/Request";
import Livewallpapers from "./pages/Livewallpapers";
import Requestedwallpapers from "./pages/Requestedwallpapers";
import Users from "./pages/Users";
import Promocodes from "./pages/Promocodes";
import Colormanagement from "./pages/Colormanagement";
import GiveawayCodes from "./pages/GiveawayCodes";
import EditUser from "./components/EditUser/EditUser";
import PendingWallpapers from "./pages/PendingWallpapers";
import Subscribeduser from "./components/Subscribeduser";
import EditLiveWallpaper from "./components/EditLiveWallpaper";
import AppVersion from "./pages/AppVersions/AppVersion";
import Home from "./pages/Home";
import ChangePassword from "./pages/ChangePassword/ChangeUserPassword";
import NotificationSend from "./pages/Notification";
import Rewards from "./pages/Rewards/Rewards";
import RewardHistory from "./pages/RewardHistory/RewardHistory";
import BoostHistory from "./pages/BoostHistory/BoostHistory";
import { baseUrl } from "../services/config";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/side" >
          <Side foldable={false} />
        </ContentRoute>
        <ContentRoute path="/foldable-side">
          <Side foldable={true} />
        </ContentRoute>

        <ContentRoute path="/social" >
          <Social foldable={false} />
        </ContentRoute>
        <ContentRoute path="/foldable-social" >
          <Social foldable={true} />
        </ContentRoute>
        <ContentRoute path="/promotion">
          <Promotion foldable={false} />
        </ContentRoute>
        <ContentRoute path="/foldable-promotion">
          <Promotion foldable={true} />

        </ContentRoute>
        <ContentRoute path="/request">
          <Request foldable={false} />
        </ContentRoute>
        <ContentRoute path="/foldable-request" >
          <Request foldable={true} />
        </ContentRoute>
        <ContentRoute path="/home">

          <Home foldable={false} />
        </ContentRoute>
        <ContentRoute path="/foldable-home" >
          <Home foldable={true} />

        </ContentRoute>
        <ContentRoute path="/giveawaycodes" component={GiveawayCodes} />
        <ContentRoute path="/livewallpapers" component={Livewallpapers} />
        <ContentRoute path="/change-password" component={ChangePassword} />
        <ContentRoute path="/requestedwallpapers" component={Requestedwallpapers} />
        <ContentRoute path="/pendingwallpapers" component={PendingWallpapers} />
        <ContentRoute path="/users" component={Users} />
        <ContentRoute path="/appversions">
          <AppVersion  foldable={false}/>
        </ContentRoute>
        <ContentRoute path="/foldable-appversions"  >
          <AppVersion     foldable={true}/>
        </ContentRoute>
        <ContentRoute path="/subscribeduser" >
        <Subscribeduser url={`${baseUrl}/admin/subscribers`}/>

        </ContentRoute>
        <ContentRoute path="/fold-subscribeduser" >
        <Subscribeduser url={`${baseUrl}/admin/fold-able-subscribers`}/>

        </ContentRoute>
        {/* <ContentRoute path="/subscribeduser" component={<Subscribeduser url={`${baseUrl}/admin/subscribers`}/>} />
        <ContentRoute path="/fold-subscribeduser" component={<Subscribeduser url={`${baseUrl}/admin/fold-able-subscribers`}/>} /> */}
        <ContentRoute path="/promocodes" component={Promocodes} />
        <ContentRoute path="/editUser" component={EditUser} />
        <ContentRoute path="/settings" component={Colormanagement} />
        <ContentRoute path="/editlivewallpaper/:id" component={EditLiveWallpaper} />
        <ContentRoute path="/notificationsend" component={NotificationSend} />
        <ContentRoute path="/rewards" component={Rewards} />
        <ContentRoute path="/rewardshistory" component={RewardHistory} />
        <ContentRoute path="/boosthistory" component={BoostHistory} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
