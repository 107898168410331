/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { baseUrl } from "../../../services/config";
import toast, { Toaster } from 'react-hot-toast';

function ChangePassword(props) {
    // Fields
    const [loading, setloading] = useState(false);
    const [isError, setisError] = useState(false);
    const dispatch = useDispatch();

    const history = useHistory();


    // Methods
    const saveUser = (values, setStatus, setSubmitting) => {
        const authtoken = localStorage.getItem("userToken");

        setloading(true);
        setisError(false);

        fetch(`${baseUrl}/update-password`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
            body: JSON.stringify(values),

        })
            .then((resp) => {
                resp.json().then((res) => {
                    console.log(res)
                    if (res.metadata) {
                        if (res.metadata.success) {
                            setloading(false);
                            toast.success(res.metadata.message);
                            history.push('/');
                        } else if (!res.metadata.success) {
                            setloading(false);
                            Object.values(res.metadata.messages).map((msg) => {
                                toast.error(msg)
                            })
                        }
                        else {
                            console.log(res.metadata.message);
                            toast(res.metadata.message);
                            setloading(false);
                            setisError(true)
                            setSubmitting(false);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            })

    };
    // UI Helpers
    const initialValues = {
        old_password: "",
        password: "",
        password_confirmation: "",
    };
    const Schema = Yup.object().shape({
        old_password: Yup.string().min(8, "Minimum 8 symbols")
            .max(50, "Maximum 50 symbols").required("Current password is required"),
        password: Yup.string().min(8, "Minimum 8 symbols")
            .max(50, "Maximum 50 symbols").required("New Password is required"),
        password_confirmation: Yup.string()
            .required("Password confirmation is required")
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveUser(values, setStatus, setSubmitting);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    return (

        <form className="card card-custom" onSubmit={formik.handleSubmit}>
            {loading && <ModalProgressBar />}

            {/* begin::Header */}
            <Toaster />
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        Change Password
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                        Change your account password
                    </span>
                </div>
                <div className="card-toolbar">
                    <button
                        type="submit"
                        className="btn btn-success mr-2"
                        disabled={
                            formik.isSubmitting || (formik.touched && !formik.isValid)
                        }
                    >
                        Save Changes
                        {formik.isSubmitting}
                    </button>
                    <Link
                        to="/"
                        className="btn btn-secondary"
                    >
                        Cancel
                    </Link>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-3x svg-icon-danger">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                    ></SVG>{" "}
                                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                Configure user passwords to expire periodically. Users will need
                                warning that their passwords are going to expire,
                                <br />
                                or they might inadvertently get locked out of the system!
                            </div>
                            <div className="alert-close" onClick={() => setisError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <i className="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            Current Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder="Current Password"
                                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                    "old_password"
                                )}`}
                                name="old_password"
                                {...formik.getFieldProps("old_password")}
                            />
                            {formik.touched.old_password &&
                                formik.errors.old_password ? (
                                <div className="invalid-feedback">
                                    {formik.errors.old_password}
                                </div>
                            ) : null}
                            <a href="#" className="text-sm font-weight-bold">
                                {/* Forgot password ? */}
                            </a>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            New Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder="New Password"
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "password"
                                )}`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="invalid-feedback">{formik.errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            Verify Password
                        </label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="password"
                                placeholder="Verify Password"
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "password_confirmation"
                                )}`}
                                name="password_confirmation"
                                {...formik.getFieldProps("password_confirmation")}
                            />
                            {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                                <div className="invalid-feedback">
                                    {formik.errors.password_confirmation}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Form */}
        </form>
    );
}

export default ChangePassword