import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import "./Side.css";
import Imagecard from "../components/Imagecard";
import { baseUrl } from "../../services/config";
import Updatecomponent from "../components/Updatecomponent";
import { toast } from "react-hot-toast";
import SocialCard from "../components/SocialCard";
import AddWallpaperModal from "../components/AddWallpaperModal/AddWallpaperModal";

const Social = ({ foldable }) => {
  const authToken = localStorage.getItem("userToken");

  const [updateModal, setupdateModal] = useState(false);
  const [selectedObject, setselectedObject] = useState({});

  const [Loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (wallapersList.length >= 3) {
      toast.error("You Can Just Add 3 Side Banners");
    } else {
      setShow(true);
    }
  };

  const [wallapersList, setwallapersList] = useState([]);

  const [showlist, setshowlist] = useState([]);
  const [images, setimages] = useState([]);

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });
    setimages(newArr2);
    setshowlist(newArr);
  };

  useEffect(() => {
    getSideWallapers();
  }, [foldable]);



  const getSideWallapers = () => {
    let url1 = "admin/banners/index/social";
    let url2 = "admin/fold-able-banners/index/social";
    setLoading(true)
    fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        setwallapersList(data.payload);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  };

  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [img, setimg] = useState("");

  //start save api
  const Socialsave = () => {
    const fdata = new FormData();
    fdata.append("type", "social");
    fdata.append("url", url);
    fdata.append("image", img);

    setLoading(true)
    fetch(`${baseUrl}/admin/banners`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          setLoading(false);
          setShow(false);
          getSideWallapers();
        }
        else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //end save api

  const editWallpaper = (card) => {
    setselectedObject(card);
    setupdateModal(true);
  };

  return (
    <>
      {Loading == true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="spinner-border text-dark  p-5 m-5 " role="status">
            <span class="sr-only ">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              // title={wallapersList.length >= 3 ? "You can just 3 banners" : ""}
              // disabled={wallapersList.length >= 3 ? true : false}
              className="mr-2 mb-2"
              variant="primary"
              onClick={handleShow}
            >
              Add New
            </Button>
          </div>
          <div className="row">
            {wallapersList &&
              wallapersList.map((item) => (

                <div className="col-md-4 ">
                  <SocialCard
                    foldable={foldable}

                    onEditClick={(card) => editWallpaper(card)}
                    reload={() => getSideWallapers()}
                    card={item}
                    title="Social"
                  />
                </div>

              ))}
          </div>
        </>
      )}

      {/* add new modal start */}
      {/* <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Social</Modal.Title>
        </Modal.Header>

        {Loading == true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="spinner-border text-dark  p-5 m-5 " role="status">
              <span class="sr-only ">Loading...</span>
            </div>
          </div>
        ) : (


          <Modal.Body className="row">
            <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="URL"
                onChange={(e) => {
                  seturl(e.target.value);
                }}
              />
            </Form.Group>

            <div className="col-12">
              <div
                className="avatar-upload-input text-center "
                style={{ marginTop: "20px" }}
              >
                <img src={Upload} alt="img" />
                <h2 className="mt-3">Upload File</h2>

                <div className="avatar-edit-input">
                  <input
                    onChange={(e) => {
                      setimg(e.target.files[0]);
                    }}
                    className="btn btn-base"
                    style={{ textAlignLast: "center" }}
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {showlist.map((pic, i) => (
                  <div className="pic" key={i + 1}>
                    <img
                      key={i + 1}
                      height={150}
                      width={150}
                      style={{ margin: 5 }}
                      src={pic}
                    />
                    <button
                      className="danger"
                      onClick={() => removeImage(i)}
                      style={{ border: "none" }}
                    >
                      <p style={{ color: "red" }}>X</p>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>



        )}


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={Socialsave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/*add new modal end */}

      <Updatecomponent
        type={"social"}
        foldable={foldable}
        show={updateModal}
        url={selectedObject.url}
        hide={() => setupdateModal(false)}
        title="Social"
        reload={() => getSideWallapers()}
        wallpaperobject={selectedObject}
      />


      <AddWallpaperModal
        foldable={foldable}
        visible={show}
        type="social"
        handleCloseModal={() => setShow(false)}
        reload={() => getSideWallapers()}

      />
    </>
  );
};

export default Social;
