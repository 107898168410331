import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SVG from "react-inlinesvg";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
// import "../../Maincatogry.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import Modal from "react-bootstrap/Modal";

import Imagecard from "./Imagecard";
import { baseUrl } from "../../services/config";
import Axios from "axios";
import toast from "react-hot-toast";
import { Formik, useFormik, Form as FormikForm, Field, } from "formik";
import * as Yup from "yup";
import { Input } from "../../_metronic/_partials/controls";

const Updatecomponent = ({ show, hide, url, type, reload, wallpaperobject, isFirst, title, foldable }) => {
  console.log(wallpaperobject);

  const authToken = localStorage.getItem("userToken");

  // const [url, seturl] = useState(wallpaperobject.url);
  const [updateimg, setupdateimg] = useState("");
  const [loading, setloading] = useState(false);
  const [imgError, setimgError] = useState("");


  const LoginSchema = Yup.object().shape({
    url: Yup.string()
      .required('Url is Required')
      .matches(/^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/,
        "Url must begin with http or https:// and end with .com "
      ),

  });
  const LoginSchema2 = Yup.object().shape({
    url: Yup.string()
      .notRequired('Url is Required')
      .matches(/^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/,
        "Url must begin with http or https:// and end with .com "
      ),

  });




  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };



  const initialValues = {
    url: isFirst ? "https://www.google.com" : url,
  };





  const formik = useFormik({
    initialValues,
    validationSchema: !isFirst ? LoginSchema : LoginSchema2,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      Updatsave(values, resetForm)
      // if (updateimg) {
      // } else {
      //   setimgError('Image is required')
      // }
    },
  })




  //start update api
  const Updatsave = (values) => {

    console.log(values)
    const fdata = new FormData();
    fdata.append("type", type);
    fdata.append("url", isFirst ? "https://www.google.com" : values.url);
    if (updateimg) {
      fdata.append("image", updateimg);
    }

    // Axios.post(`${baseUrl}/admin/banners`, fdata, {
    //   Authorization: `Bearer 13|1zboAQruugqNf2Mbny3IqQW6RGnTxwJ9jXrngKQG`,
    // })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    let url1 = `admin/banners/${wallpaperobject.id}?_method=PUT`;
    let url2 = `admin/fold-able-banners/${wallpaperobject.id}?_method=PUT`;


    setloading(true);
    fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          toast.success('Updated Successfully')
          hide();
          reload();
          setloading(false);
          // resetForm()
        }
        else if (!data.metadata.success) {
          Object.values(data.metadata.message).map(msg => {
            toast.error(msg)
            setloading(false)
          })

        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  //end update api

  return (
    <Modal
      show={show}
      onHide={hide}
      animation={false}
      size="lg"

    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {loading == true ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div class="spinner-border text-dark  p-5 m-5 " role="status">
            <span class="sr-only " >Loading...</span>
          </div>
        </div>
      ) : (


        <Modal.Body className="row">

          <Formik

            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={LoginSchema}
            // validate={validatefield }
            onSubmit={(values) => {
              Updatsave(values)
            }}
          >
            {({ handleSubmit, handleChange, setValues, handleBlur, setFieldValue, isSubmitting, isValid, errors, touched, values }) => (
              <FormikForm>
                {
                  !isFirst ?
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                      <Form.Label>URL</Form.Label>
                      <Field
                        style={{ height: '4rem' }}
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "url"
                        )}`}
                        name="url"
                        component={Input}
                        placeholder="Url"

                      />
                      {touched.url && errors.url ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.url}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                    : null
                }

                <div className="col-12">
                  <div
                    className="avatar-upload-input text-center "
                    style={{ marginTop: "20px" }}
                  >
                    <img src={Upload} alt="img" />
                    <h2 className="mt-3">Upload File</h2>

                    <div className="avatar-edit-input">
                      <input
                        onChange={(e) => {
                          setimgError("")
                          setupdateimg(e.target.files[0]);
                        }}
                        className="btn btn-base"
                        style={{ textAlignLast: "center" }}
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />

                      {imgError ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{imgError}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    width={150}
                    height={150}
                    style={{ borderRadius: "10px" }}
                    src={wallpaperobject?.image?.original_url}
                    alt="img"
                  />
                  <span style={{ color: "red", marginLeft: "5px" }}>X</span>
                </div>

                <Modal.Footer>
                  <Button variant="secondary" onClick={hide}>
                    Close
                  </Button>

                  <Button type="submit" variant="primary" onClick={() => Updatsave(values)}>
                    Update
                  </Button>

                </Modal.Footer>
              </FormikForm>


            )}
          </Formik>

        </Modal.Body>

      )}

    </Modal>
  );
};

export default Updatecomponent;
