import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import "./Side.css";
import Livewallpapercard from "../components/Livewallpapercard";
import { baseUrl } from "../../services/config";
import { toast } from "react-hot-toast";
import Pagination from "@material-ui/lab/Pagination";
import { TagsInput } from "react-tag-input-component";
import EditLiveWallpaper from "../components/EditLiveWallpaper";
import UsePagination from "../components/UsePagination";
import { FormControlLabel, Switch } from "@material-ui/core";

const Livewallpapers = () => {
  const authToken = localStorage.getItem("userToken");
  const [wallapersList, setwallapersList] = useState([]);
  const [dataloading, setdataloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [name, setname] = useState("");
  const [url_link, seturl_link] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [numberofpages, setnumberofpages] = useState(0);
  const [page, setpage] = useState(1);
  const [total, settotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [selectedunVisible, setSelectedunVisible] = useState([]);
  const [selectedWallpaper, setselectedWallpaper] = useState({})
  const [unlockGems,setUnlockGems]=useState('')
  const [isHd,setIsHd]=useState(false)
  // const [url, seturl] = useState(`https://epic.preestore.pk/api/admin/live-wallpapers?page=1`)
  const [links, setlinks] = useState([])

  const [showgif, setshowgif] = useState('')
  const [showgif2, setshowgif2] = useState('')
  const [editModal, seteditModal] = useState(false)


  const handleChangeImages = (e) => {
    let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";
    // for (let i = 0; i < e.target.files.length; i++) {
    //     imagesArray.push(e.target.files[i]);
    // }
    imagesArray.push(img);
    setimages(imagesArray);
    console.log(imagesArray);
  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    fileObj.push(event.target.files[0]);
    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }
    // console.log("file array",fileArray)
    // console.log("file obj",fileObj)
  };

  const [startDate, setStartDate] = useState(new Date());

  const [showlist, setshowlist] = useState([]);
  const [images, setimages] = useState([]);

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });
    setimages(newArr2);
    setshowlist(newArr);
  };

  useEffect(() => {
    getLiveWallapers(`${baseUrl}/admin/live-wallpapers`);
  }, []);

  const getLiveWallapers = (url) => {
    setdataloading(true);
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setnumberofpages(data.payload.per_page);
        settotal(data.payload.total);
        setlinks(data.payload.links)
        setwallapersList(data.payload.data);
        setdataloading(false);

      })
      .catch((err) => {
        console.log(err);
        setdataloading(false);
      });
  };

  const [gif, setgif] = useState("");
  const [gif2, setgif2] = useState("");
  const [video, setvideo] = useState("");
  const [video2, setvideo2] = useState("");

  const [showvideo, setshowvideo] = useState("")
  const [showvideo2, setshowvideo2] = useState("")

  const addWallpaper = () => {
    const fdata = new FormData();
    fdata.append("name", name);
    fdata.append("thumbnail", gif);
    fdata.append("video", video);
    if(gif2){
      fdata.append("hd_thumbnail", gif2);
    }
    if(video2){
      fdata.append("hd_video", video2);
    }
    fdata.append("url_link", url_link);
    if(gif2 || video2){
      if(unlockGems==''){
        return toast.error('Gems to unlock field is required')
      }else{
        fdata.append("gems_to_unlock", unlockGems );
      }
    }
    // fdata.append("type", isHd ? 2 : 1 );

    for (let i = 0; i < selected.length; i++) {
      fdata.append('visible_tags[]', selected[i])
    }
    for (let i = 0; i < selectedunVisible.length; i++) {
      fdata.append('invisible_tags[]', selectedunVisible[i])
    }
    setloading(true);
    fetch(`${baseUrl}/admin/live-wallpapers`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          setShow(false);
          getLiveWallapers(`${baseUrl}/admin/live-wallpapers`);
          toast.success(data.metadata.message);
          setloading(false);
          setSelected([])
          setSelectedunVisible([])
          setshowgif('')
          setshowvideo('')
          setshowgif2('')
          setshowvideo2('')
          setgif('')
          setgif2('')
          setvideo2('')
          seturl_link('')
          setUnlockGems('')
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map(msg => {
            toast.error(msg);
            setloading(false);
          })

          // setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const handleChange = (e, p) => {
    setwallapersList([])
    setpage(p);
    getLiveWallapers();
  };

  const count = Math.ceil(total / numberofpages);




  const editLive = () => {

  }



  const onPaginate = link => {
    getLiveWallapers(link?.url)

  }




  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Button className="mr-2 mb-2" variant="primary" onClick={handleShow}>
          Add New
        </Button>
      </div>

      {dataloading == true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="row">
            {wallapersList &&
              wallapersList.map((item) => (
                <Livewallpapercard
                  Livecard={item}
                  reload={() => getLiveWallapers(`${baseUrl}/admin/live-wallpapers`)}
                />
              ))}
          </div>



          {/* <Pagination count={10} shape="rounded" /> */}





          <UsePagination
            links={links}
            onPaginate={onPaginate}

          />
          {/* <nav aria-label="Page navigation example">
            <ul class="pagination">

              {
                links.map((link, index) => (
                  <li

                    class="page-item"><button
                      disabled={link.url == null || link.active ? true : false}
                      class="page-link"
                      onClick={() => onPaginate(link)}
                    >
                      {link.label == 'Next &raquo;' ? 'Next' : link.label == "&laquo; Previous" ? 'Previous' : link.label}
                    </button></li>
                ))
              }

            </ul>
          </nav> */}

          {/* 
          <div style={{ display: "flex", justifyContent: "flex-end", position: "absolute", bottom: 50 }}>
            {
              links.map((link, index) => (
                <button
                  className={`btn m-2 ${link.active ? 'btn-primary' : 'btn-info'}`}
                  disabled={link.url == null || link.active ? true : false}
                  onClick={() => onPaginate(link)}>
                  <div>{link.label == 'Next &raquo;' ? 'Next' : link.label == "&laquo; Previous" ? 'Previous' : link.label}</div>
                </button>
              ))
            }
          </div> */}

          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination page={page} onChange={handleChange} count={count} />
          </div> */}
        </>
      )}

      {/* {dataloading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      )} */}

      {/* add new modal start */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
      // style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Live wallpaper</Modal.Title>
        </Modal.Header>
        {loading == true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="spinner-border text-dark  p-5 m-5 " role="status">
              <span class="sr-only ">Loading...</span>
            </div>
          </div>
        ) : (
          <Modal.Body className="row">
            <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={(e) => setname(e.target.value)}
                type="text"
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
              <Form.Label>Url</Form.Label>
              <Form.Control
                onChange={(e) => seturl_link(e.target.value)}
                type="text"
                placeholder="Url"
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-12">
            <Form.Label>Visible Tags</Form.Label>
              <TagsInput
                value={selected}
                onChange={setSelected}
                name="tags"
                placeHolder="Enter Visible Tags"
              />
            </Form.Group>
            <Form.Group className="mb-3 col-md-12">
            <Form.Label>Invisible Tags</Form.Label>

              <TagsInput
                value={selectedunVisible}
                onChange={setSelectedunVisible}
                name="tags"
                placeHolder="Enter Invisible tags"
              />
            </Form.Group>
            {/* <Form.Group className="mb-3 col-md-12">
            <FormControlLabel
          control={<Switch color="primary" value={isHd} onChange={(e)=>setIsHd(e.target.checked)}/>}
          label="HD"
          labelPlacement="start"
        />
            </Form.Group> */}


            {/* <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
            <Form.Label>Gif</Form.Label>
            <input accept=".gif"  onChange={(e) => setgif(e.target.files[0])} type={"file"} />
          </Form.Group> */}

            <div className="col-6">
              <div
                className="avatar-upload-input text-center "
                style={{ marginTop: "20px" }}
              >
                <h2 className="mt-3">Upload Thumbnail</h2>
                <div className="avatar-edit-input">
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    onChange={(e) => {
                      setshowgif(URL.createObjectURL(e.target.files[0]))
                      setgif(e.target.files[0])
                    }}
                    type={"file"}
                  />
                  {showgif ?
                    <img style={{ height:'150px', width:'200px',marginTop:"20px" }} src={showgif} alt="img" />
                    : null}

                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {showlist.map((pic, i) => (
                  <div className="pic" key={i + 1}>
                    <img
                      key={i + 1}
                      height={150}
                      width={150}
                      style={{ margin: 5 }}
                      src={pic}
                    />
                    <button
                      className="danger"
                      onClick={() => removeImage(i)}
                      style={{ border: "none" }}
                    >
                      <p style={{ color: "red" }}>X</p>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-6">
              <div
                className="avatar-upload-input text-center "
                style={{ marginTop: "20px" }}
              >

                <h2 className="mt-3">Upload Video</h2>

                <div className="avatar-edit-input">
                  <input
                    onChange={(e) => {
                      setvideo(e.target.files[0])
                      console.log(e.target.files)
                      setshowvideo(URL.createObjectURL(e.target.files[0]))
                    }}
                    className="btn btn-base"
                    style={{ textAlignLast: "center" }}
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    id="imageUpload"

                  />
                  {
                    showvideo ?
                      <video
                        height={200}
                        width={200}
                        autoPlay={false}
                        style={{marginTop:"-40px"}}
                        controls
                        src={showvideo}> </video>
                      : null

                  }
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {showlist.map((pic, i) => (
                  <div className="pic" key={i + 1}>
                    <img
                      key={i + 1}
                      height={150}
                      width={150}
                      style={{ margin: 5 }}
                      src={pic}
                    />
                    <button
                      className="danger"
                      onClick={() => removeImage(i)}
                      style={{ border: "none" }}
                    >
                      <p style={{ color: "red" }}>X</p>
                    </button>
                  </div>
                ))}
              </div>

            </div>
        <div className="" style={{width:"100%",borderTop:"1px solid gray",paddingTop:"20px",marginTop:"10px"}}>
            <h2 style={{textAlign:"center"}}>Upload Hd wallpaper</h2>
            <div className="row">
            <Form.Group style={{padding:"0px 24px"}} className="mb-3 col-md-12" controlId="formBasicEmail">
              <Form.Label>Gems To Unlock</Form.Label>
              <Form.Control
                onChange={(e) => setUnlockGems(e.target.value)}
                type="number"
                placeholder="Enter Gems"
              />
            </Form.Group>
            <div className="col-6">
              <div
                className="avatar-upload-input text-center "
                style={{ marginTop: "20px" }}
              >
                <h2 className="mt-3">Upload Hd Thumbnail</h2>
                <div className="avatar-edit-input">
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    onChange={(e) => {
                      setshowgif2(URL.createObjectURL(e.target.files[0]))
                      setgif2(e.target.files[0])
                    }}
                    type={"file"}
                  />
                  {showgif2 ?
                    <img  style={{ height:'150px', width:'200px',marginTop:"20px" }} src={showgif2} alt="img" />
                    : null}

                </div>
              </div>
              {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                {showlist.map((pic, i) => (
                  <div className="pic" key={i + 1}>
                    <img
                      key={i + 1}
                      height={150}
                      width={150}
                      style={{ margin: 5 }}
                      src={pic}
                    />
                    <button
                      className="danger"
                      onClick={() => removeImage(i)}
                      style={{ border: "none" }}
                    >
                      <p style={{ color: "red" }}>X</p>
                    </button>
                  </div>
                ))}
              </div> */}
            </div>

            <div className="col-6">
              <div
                className="avatar-upload-input text-center "
                style={{ marginTop: "20px" }}
              >

                <h2 className="mt-3">Upload Hd Video</h2>

                <div className="avatar-edit-input">
                  <input
                    onChange={(e) => {
                      setvideo2(e.target.files[0])
                      console.log(e.target.files)
                      setshowvideo2(URL.createObjectURL(e.target.files[0]))
                    }}
                    className="btn btn-base"
                    style={{ textAlignLast: "center" }}
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    id="imageUpload"

                  />
                  {
                    showvideo2 ?
                      <video
                        height={200}
                        width={200}
                        autoPlay={false}
                        style={{marginTop:"-40px"}}
                        controls
                        src={showvideo2}> </video>
                      : null

                  }
                </div>
              </div>
              {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                {showlist.map((pic, i) => (
                  <div className="pic" key={i + 1}>
                    <img
                      key={i + 1}
                      height={150}
                      width={150}
                      style={{ margin: 5 }}
                      src={pic}
                    />
                    <button
                      className="danger"
                      onClick={() => removeImage(i)}
                      style={{ border: "none" }}
                    >
                      <p style={{ color: "red" }}>X</p>
                    </button>
                  </div>
                ))}
              </div> */}

            </div>
            </div>
        </div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addWallpaper}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/*add new modal end */}




    </>
  );
};

export default Livewallpapers;
