import Axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../services/config'
import TextFieldsExamplesPage from '../../modules/GoogleMaterialExamples/inputs/TextFieldsExamplesPage'

function SendNotification({
    show,
    hideModal,
    listokens
}) {

    const authToken = localStorage.getItem("userToken");

    // console.log(listokens);
    const [loading, setloading] = useState(false)
    const [title, setTitle] = useState("")
    const [body, setbody] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [imageUrlshow, setImageUrlshow] = useState("")
    const [imgBtn, setimgBtn] = useState(false)
    const [imageLoading, setimageLoading] = useState(false)


    const checker = () => {
        if (title == "" || body == "") {
            toast.error('Please enter a title and body')
        } else {
            sendNotifcationRequest()
        }
    }

    const sendNotifcationRequest = () => {
        setloading(true)
        const imgData = {
            title,
            body,
            image: imageUrlshow,
        }
        const data = {
            title,
            body,
        }

        listokens.map(async (item, index) => {
            // const notification = {
            //     title,
            //     body,
            //     sound: 'default' // notification sound 
            // };

            await fetch("https://fcm.googleapis.com/fcm/send", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "key=AAAACi0YJLI:APA91bH8mpgnhlkZQQ4indjxX6cyH2-bu5deME-pkTnztyIaUOrkSDd3X7K_ZiUkP0dQNTDfNifCrZonmf87sbfZEycST9I6HrBbx4tAO1loTt1ZaLaGCPLlg3WCAYDWTv3KoYGrLSS0",
                },
                method: "POST",
                body: imageUrlshow ? JSON.stringify({
                    notification: imgData,
                    to: item,
                }) : JSON.stringify({
                    notification: data,
                    to: item,
                })
            })
                .then((response) => response.json())
                .then((data) => {
                    setloading(false)
                    console.log("response of notifications===>", data);
                    if (index + 1 == listokens.length + 1) {
                        setloading(false)
                        // hideModal()
                    }
                })
                .catch((error) => {
                    setloading(false)
                    console.log("notification error====>", error);
                });
        });



        // fetch(`https://epicwallaperadmin.herokuapp.com/send-notification`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: imageUrlshow ? JSON.stringify(imgData) : JSON.stringify(data)
        // }).then(res => res.json())
        //     .then(data => {
        //         console.log(data)
        //         hideModal()
        //         setloading(false)
        //         toast.success(data.message)
        //         setImageUrlshow('')
        //     }).catch(err => {
        //         setloading(false)
        //         console.log(err)
        //     })

    }


    const onImageChange = img => {
        setimageLoading(true)
        // setImageUrl(img)
        // setImageUrlshow(URL.createObjectURL(img))
        // console.log(URL.createObjectURL(img));

        const fdata = new FormData()
        fdata.append('image', img)
        Axios.post(`${baseUrl}/admin/notification-image`, fdata, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => {
            setImageUrlshow(res.data)
            setimageLoading(false)

        }).catch(err => {
            setimageLoading(false)
            console.log(err)
        })

    }




    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Send Notification To All</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <input
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder='Title of Notification'
                    className='form-control m-2'
                />

                <textarea
                    rows={2}
                    onChange={(e) => setbody(e.target.value)}
                    placeholder="Body of Notification"
                    className='form-control m-2'
                />

                {/* <button className='btn btn-info'>Click here for  remote url</button> */}
                {
                    imageLoading ?
                        <Spinner animation='border' />
                        :
                        <input
                            type={'file'}
                            onChange={(e) => onImageChange(e.target.files[0])}
                            className='form-control m-2'
                        />

                }

                {/* <input 
                 className='form-control m-2'
                placeholder='Remote Image Url' /> */}
                {imageUrlshow ? <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                }}><span onClick={() => setImageUrlshow('')} className='btn btn-danger m-2'>Remove</span></div> : null}
                {
                    imageUrlshow ?
                        <img
                            loading={'lazy'}
                            style={{
                                height: 'auto',
                                width: '100%',
                                objectFit: 'contain'
                            }}
                            src={imageUrlshow}
                        />
                        : null
                }

            </Modal.Body>
            <Modal.Footer>
                {
                    loading ? <Spinner animation="border" /> :
                        <>
                            <button
                                disabled={imageLoading ? true : false}
                                onClick={checker}
                                className='btn btn-primary'>
                                Send
                            </button>
                            <button
                                onClick={hideModal}
                                className='btn btn-danger'>
                                Close


                            </button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default SendNotification