import React, { useState, Component, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SVG from "react-inlinesvg";
import toast from 'react-hot-toast'
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import EditVersion from "./EditVersion";
import PublishModal from "../../components/PublishModal/PublishModal";
// import EditPromo from "../components/EditPromo/EditPromo";
import { useLocation } from "react-router-dom";

const AppVersions = ({ foldable }) => {
    const location = useLocation();
    console.log(location.pathname);
    const [addLoading, setaddLoading] = useState(false)
    const [loading, setloading] = useState(false)
    const authToken = localStorage.getItem("userToken");
    const [deleteLoading, setdeleteLoading] = useState(false)
    const [selectedObject, setselectedObject] = useState({})
    const [show, setShow] = useState(false);
    const [publishModalVisible, setpublishModalVisible] = useState(false)

    const [isPublishable, setisPublishable] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedId, setselectedId] = useState('');
    const [versionCodeList, setversionCodeList] = useState([]);
    const [unPublishedversionCodeList, setunPublishedversionCodeList] = useState([]);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [editModal, seteditModal] = useState(false);

    const handleShow2 = id => {
        setselectedId(id)
        setShow2(true)
    };

    //   const [startDate, setStartDate] = useState(new Date());
    const [value, setValue] = useState(new Date());


    //multiple select states
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])


    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };



    const initialValues = {
        version: "",


    };


    const LoginSchema = Yup.object().shape({
        version: Yup.string()
            .required('Version number is required')


    });




    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setaddLoading(true)

            const data = {
                version: values.version,

            }
            console.log(data)
            let url1 = 'admin/versions';
            let url2 = 'admin/fold-able-versions';

            fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(data)
            }).then(res => res.json())
                .then((data) => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message);
                        setSubmitting(false)
                        getCodes()
                        setaddLoading(false)
                        handleClose()

                    }
                    else if (!data.metadata.success) {
                        Object.values(data.metadata.message).map(msg => {
                            toast.error(msg)
                            setaddLoading(false)
                            setSubmitting(false)
                        })
                    }
                    console.log(data)
                }).catch(err => {
                    setSubmitting(false)
                    setaddLoading(false)

                    console.error("add codes error", err)
                })

        }
    })






    useEffect(() => {
        getCodes()
    }, [foldable])



    const getCodes = () => {
        let url1 = 'admin/versions';
        let url2 = 'admin/fold-able-versions';
        setloading(true)
        fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {

                let filterdUnpublised = data.payload.filter(ver => ver.publish == 0)
                let filterdpublised = data.payload.filter(ver => ver.publish == 1)


                setversionCodeList(filterdpublised)
                setunPublishedversionCodeList(filterdUnpublised)
                setloading(false)

                console.log(data);
            }).catch(err => {
                setloading(false)
                console.error("codes error", err)
            });

    }




    const deleteCode = () => {
        setdeleteLoading(true)
        let url1 = `admin/versions/${selectedId}`;
        let url2 = `admin/fold-able-versions/${selectedId}`;
        fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                console.log(data);
                if (data.metadata.success) {
                    handleClose2()
                    getCodes()
                    setdeleteLoading(false)
                    toast.success(data.metadata.message);
                    // setPromocodesList(data.payload);

                }
            })
            .catch(err => {
                console.error("codes error", err)
                setdeleteLoading(false)
            });
    }


    const editCode = (item, bol) => {

        console.log(bol);

        setisPublishable(bol)
        setselectedObject(item)
        seteditModal(true)
    }
    const openPublishModel = item => {
        setselectedObject(item)
        setpublishModalVisible(true)
    }


    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                }}
            >
                <Button
                    // title={wallapersList.length >= 2 ? "You Can Add Only 2 Side Banners" : ""}
                    // disabled={wallapersList.length >= 2 ? true : false}
                    className="mr-2 mb-2"
                    style={{ width: "100px" }}
                    variant="primary"
                    onClick={handleShow}
                >
                    Add
                </Button>

            </div>

            <div className="row">
                <div className="col-md-6">
                    <Paper style={{ padding: "20px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <h3>App Versions</h3>
                            {/* <Button

            className="mr-2 mb-2"

            variant="danger"
            onClick={handleDeleteAll}
          >
            Delete Selected
          </Button> */}
                        </div>

                        <hr style={{ marginLeft: "-20px", marginRight: "-20px" }} />

                        <form className="form-label-right">
                            <div className="form-group row">
                                {/* <Form.Group className="mb-3 col-md-3" controlId="formBasicEmail">
              <Form.Label>THLN</Form.Label>
              <Form.Control type="text" placeholder="THLN" />
            </Form.Group> */}
                            </div>
                        </form>


                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Version</th>
                                    <th>Version key</th>
                                    <th>Edit</th>
                                    <th>Delete</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    versionCodeList && versionCodeList.map((item, index) => (
                                        <tr key={item.id}>
                                            <td style={{ verticalAlign: "center" }}>{index + 1}</td>
                                            <td style={{ verticalAlign: "center" }}>{item.version}</td>
                                            <td style={{ verticalAlign: "center" }}>{item.key}</td>

                                            <td>
                                                <a className="btn btn-icon btn-light btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary" onClick={() => editCode(item, true)}>
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
                                                        ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                            <td>
                                                <a className="btn btn-icon btn-light btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger" onClick={() => handleShow2(item.id)}>
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                                                        ></SVG>
                                                    </span>
                                                </a>
                                            </td>

                                        </tr>




                                    ))
                                }
                            </tbody>
                        </Table>
                        {loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner animation="border" /></div>}

                    </Paper>

                </div>

                <div className="col-md-6">
                    <Paper style={{ padding: "20px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <h3>Waiting List</h3>


                        </div>
                        <hr style={{ marginLeft: "-20px", marginRight: "-20px" }} />
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Version</th>
                                    <th>Version key</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                    <th>Publish</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    unPublishedversionCodeList && unPublishedversionCodeList.map((item, index) => (
                                        <tr key={item.id}>
                                            <td style={{ verticalAlign: "center" }}>{index + 1}</td>
                                            <td style={{ verticalAlign: "center" }}>{item.version}</td>
                                            <td style={{ verticalAlign: "center" }}>{item.key}</td>


                                            <td>
                                                <a className="btn btn-icon btn-light btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary" onClick={() => editCode(item, false)}>
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
                                                        ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                            <td>
                                                <a className="btn btn-icon btn-light btn-sm">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger" onClick={() => handleShow2(item.id)}>
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                                                        ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                            <td style={{ verticalAlign: "center" }}>
                                                <button onClick={() => openPublishModel(item)} className="btn btn-info">
                                                    Publish
                                                </button>
                                            </td>
                                        </tr>




                                    ))
                                }
                            </tbody>
                        </Table>
                    </Paper>
                </div>

            </div>

            <Modal

                show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Version Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        <div className="row">
                            <div className="col-md-6 m-2">
                                <label>Version Code</label>
                                <input
                                    className={`form-control form-control-solid h-auto py-2 px-3 ${getInputClasses(
                                        "version"
                                    )}`}
                                    name="version"
                                    {...formik.getFieldProps("version")}
                                />
                                {formik.touched.version && formik.errors.version ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.version}</div>
                                    </div>
                                ) : null}
                            </div>

                        </div>

                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={formik.isSubmitting}
                                className={`btn btn-primary`}
                            >
                                <span style={{ textAlign: "center" }}>Add Code</span>
                                {addLoading && <Spinner animation="border" />}
                            </button>
                        </Modal.Footer>
                    </form>

                </Modal.Body>
            </Modal>

            {/* delete Model */}
            <Modal
                show={show2}
                onHide={handleClose2}
                animation={false}
                size="md"
                // style={{ marginLeft: "140px" }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Version Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">





                    <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                        <h3 className="text-center">Are you sure ? you want to delete it</h3>

                    </Form.Group>



                </Modal.Body>


                <Modal.Footer>
                    {
                        deleteLoading ?

                            <Spinner animation="border" />
                            :
                            <>
                                <Button variant="secondary" onClick={handleClose2}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={deleteCode}>
                                    Delete
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
            {/* delete Model */}
            <EditVersion
                foldable={foldable}

                isPublishable={isPublishable}
                visible={editModal}
                selectedObject={selectedObject}
                hide={() => seteditModal(false)}
                reload={() => getCodes()}
            />


            <PublishModal
                foldable={foldable}

                closeModal={() => setpublishModalVisible(false)}
                modalVisible={publishModalVisible}
                item={selectedObject}
                reload={() => getCodes()}
            />


        </div >
    );
};

export default AppVersions;
