/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import Routes from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { getTokenfirebase, onMessageListener } from "../firebase/firebase";
import toast, { Toaster } from 'react-hot-toast';
import sound from '../music/noti.mp3'

export default function App({ store, persistor, basename }) {


  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  const [payload, setpayload] = useState()
  getTokenfirebase(setTokenFound);



  const notify = () => toast(<ToastDisplay />);

  function ToastDisplay() {
    return (
      <div onClick={() => window.location.href = '/pendingwallpapers'}>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };


  onMessageListener().then(payload => {
    setShow(true);
    setpayload(payload)
    setNotification({ title: payload.data.title, body: payload.data.body })
  }).catch(err => console.log('failed: ', err));


  useEffect(() => {
    if (notification?.title) {
      notify()
      let audio = new Audio(sound)
      audio.play()
      setTimeout(() => {
        audio.pause()
      }, 1000);
      console.log(payload)
    }
  }, [notification])



  console.log(payload)



  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  <Routes />
                  <Toaster />
                </AuthInit>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
