import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import "./Side.css";
import Requestedwallpapercard from "../components/Requestedwallpapercard";
import { baseUrl } from "../../services/config";
import { toast } from "react-hot-toast";
import UsePagination from "../components/UsePagination";




const PendingWallpapers = () => {

  const compref = useRef()


  const authToken = localStorage.getItem("userToken");

  const [wallapersList, setwallapersList] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [links, setlinks] = useState([])
  const [numberofpages, setnumberofpages] = useState(0);
  const [page, setpage] = useState(1);
  const [total, settotal] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);

  const [confirmModal, setconfirmModal] = useState(false)
  const [deleteloading, setdeleteloading] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //multiple select states
  const [MasterChecked, setMasterChecked] = useState(false)
  const [SelectedList, setSelectedList] = useState([])


  const handleChangeImages = (e) => {
    let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";

    imagesArray.push(img);
    setimages(imagesArray);
    console.log(imagesArray);
  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    fileObj.push(event.target.files[0]);
    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }
  };

  const [startDate, setStartDate] = useState(new Date());

  const [showlist, setshowlist] = useState([]);
  const [images, setimages] = useState([]);

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });
    setimages(newArr2);
    setshowlist(newArr);
  };

  useEffect(() => {

    getrequestedWallapers(`${baseUrl}/admin/unapproved-requested-wallpapers`);
  }, []);
  useEffect(() => {

    compref.current.scrollIntoView()
  }, []);

  const getrequestedWallapers = (url) => {
    enableLoading()
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {

        let filteredArr = data.payload.data


        let checkboxes = filteredArr;
        filteredArr.map((item, index) => {
          item["selected"] = false;
          checkboxes[index] = item;
        });
        setwallapersList([...checkboxes]);
        setnumberofpages(data.payload.per_page);
        settotal(data.payload.total);
        setlinks(data.payload.links)
        console.log(checkboxes);
        disabledLoading()
      })
      .catch((err) => {
        disabledLoading()
        console.log(err);
      });
  };

  const Underdevelopment = () => {
    toast.error("Under Development");
  };



  const enableLoading = () => {
    setloading(true);

  }

  const disabledLoading = () => {
    setloading(false);

  }





  // const allcheckboxChecked = ()=>{

  //   const allcheckboxChecked = () => {
  //     let middlearray = [];
  //     let staffarray = [];

  //     if (checkallcheckbox) {
  //         setcheckallcheckbox(false);
  //         allstudentList.map((item, i) => {
  //             item.checked = false;
  //             middlearray.push(item);
  //         });
  //         // generateSalary()
  //         setselectedStudents([]);
  //     } else {
  //         setcheckallcheckbox(true);
  //         allstudentList.map((item, i) => {
  //             item.checked = true;
  //             staffarray.push(item.id);
  //             middlearray.push(item);
  //         });
  //         setselectedStudents([...staffarray]);

  //         // generateSalary()
  //     }
  //     // console.log(selectedcampusstaff);
  //     setallstudentsList([...middlearray]);
  // };
  // }



  const onPaginate = link => {
    getrequestedWallapers(link?.url)
    sessionStorage.setItem('epic_remember_url', link.url)

  }

  // multiple select
  const onMasterCheck = e => {
    let tempList = wallapersList;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    let filtered = wallapersList.filter((e) => e.selected)
    setwallapersList(tempList);
    setMasterChecked(e.target.checked)
    setSelectedList(filtered)

    console.log(filtered)

  }

  const onItemCheck = (e, item) => {

    console.log('event is', e.target.checked)
    console.log('item is', item)
    let tempList = wallapersList;


    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = wallapersList.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setMasterChecked(totalItems === totalCheckedItems)
    setwallapersList(tempList);
    setSelectedList(wallapersList.filter((e) => e.selected))

    console.log(wallapersList.filter((e) => e.selected))

  }



  const deleteSelected = () => {

    let ids = SelectedList.map(item => item.id)
    setdeleteloading(true)
    //console.log(ids)

    fetch(`${baseUrl}/admin/requested-wallpapers/bulk-delete/${ids}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      }
    }).then((response) => response.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message)
          setconfirmModal(false)
          setdeleteloading(false)
          const rememberLink = sessionStorage.getItem('epic_remember_url')
          if (rememberLink) {
            getrequestedWallapers(rememberLink)
          } else {
            getrequestedWallapers(`${baseUrl}/admin/unapproved-requested-wallpapers`)

          }
        }
        console.log(data)
      }).catch(err => {
        setconfirmModal(false)
        setdeleteloading(false)
        console.log(err)
      })

  }


  const openConfirm = () => {
    if (SelectedList.length > 0) {
      setconfirmModal(true)
    }
    else {
      toast.error('Please select some codes')
    }
  }





  return (
    <>
      <div
        ref={compref}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <input
            checked={MasterChecked}
            onChange={(e) => onMasterCheck(e)}
            placeholder="Checkbox"
            type="checkbox"
          />{" "}
          <span style={{ marginleft: "15px" }}>Check All</span>

        </div>


        <Button
          onClick={openConfirm}
          className="mr-2 mb-2"
          variant="danger"
          style={{ width: "100px" }}
        >
          Delete
        </Button>
      </div>


      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <Spinner animation="border" />}
      </div>


      <div className="row">
        {wallapersList &&
          wallapersList.map((item) => (
            <Requestedwallpapercard
              onItemCheck={(e, item) => onItemCheck(e, item)}
              reload={() => {
                const rememberLink = sessionStorage.getItem('epic_remember_url')
                if (rememberLink) {
                  getrequestedWallapers(rememberLink)
                } else {
                  getrequestedWallapers(`${baseUrl}/admin/unapproved-requested-wallpapers`)
                }

              }}
              requestedCard={item}
            />
          ))}
      </div>




      <UsePagination
        links={links}
        onPaginate={onPaginate}

      />

      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        {
          links.map((link, index) => (
            <button
              className={`btn m-2 ${link.active ? 'btn-primary' : 'btn-info'}`}
              disabled={link.url == null || link.active ? true : false}
              onClick={() => onPaginate(link)}>
              <div>{link.label == 'Next &raquo;' ? 'Next' : link.label == "&laquo; Previous" ? 'Previous' : link.label}</div>
            </button>
          ))
        }
      </div> */}

      {/* add new modal start */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Requested Wallpapers</Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
            <Form.Label>URL</Form.Label>
            <Form.Control type="text" placeholder="URL" />
          </Form.Group>

          <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
            <Form.Label>TYPE</Form.Label>
            <Form.Control type="text" placeholder="TYPE" />
          </Form.Group>

          <div className="col-12">
            <div
              className="avatar-upload-input text-center "
              style={{ marginTop: "20px" }}
            >
              <img src={Upload} alt="img" />
              <h2 className="mt-3">Upload File</h2>

              <div className="avatar-edit-input">
                <input
                  onChange={(e) => {
                    handleChangeImages(e);
                    showImages(e);
                  }}
                  className="btn btn-base"
                  style={{ textAlignLast: "center" }}
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {showlist.map((pic, i) => (
                <div className="pic" key={i + 1}>
                  <img
                    key={i + 1}
                    height={150}
                    width={150}
                    style={{ margin: 5 }}
                    src={pic}
                  />
                  <button
                    className="danger"
                    onClick={() => removeImage(i)}
                    style={{ border: "none" }}
                  >
                    <p style={{ color: "red" }}>X</p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      {/*add new modal end */}
      <Modal
        show={confirmModal}
      >
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          {
            deleteloading ?
              <Spinner animation="border" />
              :
              <>
                <button
                  onClick={() => setconfirmModal(false)}
                  className="btn btn-danger">
                  Cancel
                </button>
                <button
                  onClick={deleteSelected}
                  className="btn btn-primary">
                  Delete
                </button>
              </>

          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PendingWallpapers;
