import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../../services/config";
import { DatePicker, Modal } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import "./style.css";
const { RangePicker } = DatePicker;
const ExportHistoryModal = ({ HideModal, visible }) => {
  const authToken = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [daterange, setDateRange] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const getData = () => {
    console.log(daterange);
    if (!startDate || !endDate) {
      return toast.error("Please select the start and end date");
    }

    setLoading(true);
    fetch(
      `${baseUrl}/admin/boosted-wallpaper-history-export/start-date/${startDate}/end-date/${endDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          let resultantArray = [];
          data.payload.map((item) => {
            resultantArray.push({
              id: item.id,
              first_name: item.user?.first_name,
              last_name: item.user?.last_name,
              email: item.user?.email,
              userGems: item.user?.gems,
              requested_wallpaper_name: item.requested_wallpaper?.name,
              requested_wallpaper_ratting: item.requested_wallpaper?.rating,
              usedGems: item.gems,
              timestamp: item.timestamp
            });
          });
          setExportData(resultantArray);
          setLoading(false);
          toast.success('Generated Successfully')
        } else {
          setLoading(false);
          setExportData([]);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  const headers = [
    { label: "ID", key: "id" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "User Gems", key: "userGems" },
    { label: "Wallpaper Name", key: "requested_wallpaper_name" },
    { label: "Rating", key: "requested_wallpaper_ratting" },
    { label: "Used Gems", key: "usedGems" },
    { label: "Boosted At", key: "timestamp" },
  ];




  console.log("exportData", exportData)


  const onChange = (value, dateString) => {
    if (value == null) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setEndDate(dateString[1]);
      setStartDate(dateString[0]);
    }
  };
  return (
    <Modal
      open={visible}
      // onOk={handleOk}
      onCancel={() => {
        setStartDate(null);
        setEndDate(null);
        setDateRange([]);
        setExportData([]);
        HideModal();
      }}
      title='Export boost history'
      closable={!loading}
      footer={[
        <>
          <button
            onClick={() => {
              setExportData([]);
              setDateRange([]);
              setStartDate(null);
              setEndDate(null);
              HideModal();
            }}
            className="btn btn-danger"
            disabled={loading}
          >
            Close
          </button>

          <button disabled={loading} onClick={getData} className="btn btn-primary mx-2">
            Genrate
          </button>

          {exportData.length > 0 && (
            <CSVLink
              filename={`Boost History`}
              style={{ textDecoration: "none", color: "white" }}
              headers={headers}
              data={exportData}


            >
              <button onClick={() => {
                setExportData([]);
                setDateRange([]);
                setStartDate(null);
                setEndDate(null);
                HideModal();

              }} className="btn btn-primary mx-2">Export</button>
            </CSVLink>
          )}

        </>
      ]}

    >
      <div className="row">
        <div className="col-md-12">
          <label>
            <b>Select Start and End Date</b>
          </label>
          <RangePicker style={{ height: "38px", width: "100%" }} format="YYYY-MM-DD" onChange={onChange} />
        </div>
      </div>


    </Modal>

  );
};

export default ExportHistoryModal;
