import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls'
import { baseUrl } from "../../../services/config";
import { useState } from "react";
import * as uiHelpers from "../../modules/ECommerce/pages/products/ProductsUIHelpers";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { DatePicker, Space } from 'antd';
import { Table } from "react-bootstrap";
import UsePagination from "../../components/UsePagination";
import usePagination from "../../components/usePagination/usePagination";
import Pagination from "@material-ui/lab/Pagination";
import { CSVLink } from "react-csv";
import './styles.css'


const { RangePicker } = DatePicker;

const { ExportCSVButton } = CSVExport;


const RewardHistory = () => {


    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };

    const [queryParams, setQueryParamsBase] = useState(initialFilter);


    // const productsUIContext = useProductsUIContext();
    // const productsUIProps = useMemo(() => {
    //     return {
    //         // ids: productsUIContext.ids,
    //         // setIds: productsUIContext.setIds,

    //         openEditProductPage: productsUIContext.openEditProductPage,
    //         openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
    //     };
    // }, [productsUIContext]);


    const updatingQueryParams = (page) => {
        console.log(page);
        setQueryParamsBase({
            pageNumber: page,
        });
    };

    const onPaginationSelectChange = () => {
        setQueryParamsBase({
            pageNumber: 1,
        });
    };

    const authToken = localStorage.getItem('userToken')

    const [loading, setLoading] = useState(false)
    const [rewardsList, setRewardList] = useState([])
    const [links, setlinks] = useState([]);
    const [numberofpages, setnumberofpages] = useState(0);
    const [total, settotal] = useState(0);
    const [dateChangeData, setDateChangeData] = useState([])
    const [date, setDate] = useState([])
    const [csvList, setCsvList] = useState([]);
    const [exportLoading,setExportLoading]=useState(false)

    const getHistory = (url) => {
        setLoading(true)
        setDateChangeData([])
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setRewardList(data.payload.data)
                    setnumberofpages(data.payload.per_page);
                    settotal(data.payload.total);
                    setlinks(data.payload.links)
                    setLoading(false)
                } else {
                    setLoading(false)

                    setRewardList([])
                }
            }).catch(err => {
                setLoading(false)

                console.log(err);
            })
    }



    useEffect(() => {
        getHistory(`${baseUrl}/admin/user-rewards-history`)
    }, [])

    const onPaginate = (link) => {
        getHistory(link?.url);
    };

    // Table columns
    const columns = [
        {
            dataField: "id",
            text: "Reward ID",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.first_name",
            text: "First Name",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.last_name",
            text: "Last Name",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "user.email",
            text: "Email",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "reward.day",
            text: "Reward Day",
            sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: "claimed_at",
            text: "Claimed At",
            sort: true,
            // sortCaret: sortCaret,
        },


    ];

    const paginationOptions = {
        custom: true,
        totalSize: rewardsList?.length,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
    };


    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-success" onClick={handleClick}>Export CSV</button>
            </div>
        );
    };


    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        if (value == null) {
            getHistory(`${baseUrl}/admin/user-rewards-history`)
            setCsvList([])
        } else {
            setDate(dateString)
        }
    };

    const search = () => {
        setLoading(true)
        setRewardList([])
        fetch(`${baseUrl}/admin/user-rewards-history/${date[0]}/${date[1]}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setDateChangeData(data.payload)
                    const csvArray = [];
                    data.payload.map((item) =>
                        csvArray.push({
                            id: item.id,
                            first_name: item.user?.first_name,
                            last_name: item.user?.last_name,
                            email: item.user?.email,
                            reward: item?.reward.day,
                            claim: item?.claimed_at,
                        })
                    );
                    console.log(csvArray)
                    setCsvList(csvArray);
                    setLoading(false)
                } else {
                    setLoading(false)
                    setDateChangeData([])
                }
            }).catch(err => {
                setLoading(false)

                console.log(err);
            })
    }

    let [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const count = Math.ceil(dateChangeData.length / PER_PAGE);
    const _DATA = usePagination(dateChangeData, PER_PAGE);


    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const headers = [
        { label: 'REWARD ID', key: 'id' },
        { label: 'FIRST NAME', key: 'first_name' },
        { label: 'LAST NAME', key: 'last_name' },
        { label: 'EMAIL', key: 'email' },
        { label: 'REWARD DAY', key: 'reward' },
        { label: 'CLAIMED AT', key: 'claim' },
    ];


    console.log("csvArray", csvList);

    return (
        <Card>



            <CardBody>
                <div className="row">
                    <div className="col-md-4">
                        <RangePicker
                            format="YYYY-MM-DD"
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <button onClick={() => search()} className="btn btn-primary">Search</button>
                        {csvList.length > 0 ? <CSVLink
                            filename="Reward History"
                            style={{ textDecoration: 'none', color: 'white' }}
                            headers={headers}
                            data={csvList}
                        >
                            <button
                                onClick={()=>{
                                    setExportLoading(true)
                                    setTimeout(() => {
                                    setExportLoading(false)
                                    setCsvList([])
                                    }, 2000);
                                }}
                                disabled={exportLoading}
                                className="btn btn-primary mx-2"
                            >

                                Export
                            </button>
                        </CSVLink> : ''}
                    </div>
                </div>


                {/* <ToolkitProvider
                    keyField="id"
                    data={rewardsList}
                    columns={columns}
                    exportCSV
                >
                    {
                        props => (

                            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                                {({ paginationProps, paginationTableProps }) => {
                                    return (
                                        <>
                                            <Pagination
                                                updatingQueryParams={updatingQueryParams}
                                                onPaginationSelectChange={onPaginationSelectChange}
                                                isLoading={loading}
                                                paginationProps={paginationProps}
                                            >
                                                <MyExportCSV {...props.csvProps} />
                                                <BootstrapTable
                                                    wrapperClasses="table-responsive"
                                                    bordered={false}
                                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                                    bootstrap4
                                                    keyField="id"
                                                    data={rewardsList}
                                                    columns={columns}
                                                    defaultSorted={uiHelpers.defaultSorted}
                                                    {...paginationTableProps}
                                                    {...props.baseProps}
                                                
                                                ></BootstrapTable>
                                            </Pagination>
                                           
                                        </>
                                    );
                                }}
                            </PaginationProvider>
                        )
                    }
                </ToolkitProvider> */}
                {loading && <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div class="spinner-border">
                        {/* <span class="sr-only">Loading...</span> */}
                    </div>
                </div>}
                {
                    rewardsList.length > 0 ?
                        <>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>REWARD ID</th>
                                        <th>FIRST NAME</th>
                                        <th>LAST NAME</th>
                                        <th>EMAIL</th>
                                        <th>REWARD DAY</th>
                                        <th>CLAIMED AT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rewardsList?.map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.user.first_name}</td>
                                                <td>{item.user.last_name}</td>
                                                <td>{item.user.email}</td>
                                                <td>{item.reward.day}</td>
                                                <td>{item.claimed_at}</td>
                                            </tr>
                                        ))

                                    }
                                    <tr>

                                    </tr>
                                </tbody>
                            </Table>
                            <UsePagination links={links} onPaginate={onPaginate} />
                        </>
                        : ''
                }


                {
                    dateChangeData.length > 0 ?
                        <>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>REWARD ID</th>
                                        <th>FIRST NAME</th>
                                        <th>LAST NAME</th>
                                        <th>EMAIL</th>
                                        <th>REWARD DAY</th>
                                        <th>CLAIMED AT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _DATA.currentData()?.map((item) => (
                                            <tr>
                                                <td>{item.id}</td>
                                                <td>{item.user.first_name}</td>
                                                <td>{item.user.last_name}</td>
                                                <td>{item.user.email}</td>
                                                <td>{item.reward.day}</td>
                                                <td>{item.claimed_at}</td>
                                            </tr>
                                        ))

                                    }
                                    <tr>

                                    </tr>
                                </tbody>
                            </Table>

                            <Pagination
                                count={count}
                                size="medium"
                                page={page}
                                variant="outlined" shape="rounded"
                                onChange={handleChange}
                            />
                        </>
                        : ''
                }

            </CardBody>
        </Card>
    )
}

export default RewardHistory